import { registerWidget } from '../../../js/core/widget/widget-directory'
import { apiCaller } from '../../../js/helpers/api-caller'
import registeredEvents from '../../../js/helpers/registered-events'
import { accountSpecialLuggageEvents } from '../../../js/document/event-types'

const EventEmitter = require('eventemitter3')

const widgetApi = 'w-account-special-luggage'

const urlParamName = 'specialLuggageOpen'

const transportTypeOptions = {
  flight: 'flight',
  bus: 'bus',
  ownTransport: 'own-transport'
}

const medicalTypeOptions = {
  bag: 'bag',
  other: 'other',
  manualWheelchair: 'manual-wheelchair',
  electricWheelchair: 'electric-wheelchair',
  cpap: 'cpap',
  otherMedicalEquipment: 'other-medical-equipment',
  oxygen: 'oxygen'
}

const luggageTypeOptions = {
  medical: 'medical',
  pet: 'pet',
  bike: 'bike'
}

const attr = {
  track: 'data-track'
}

const widgetQueries = {
  medicalLuggageText: `data-${widgetApi}__medical-text`,
  luggageTypeDropdown: `data-${widgetApi}__luggage-type`,
  participantDropdown: `data-${widgetApi}__participant`,
  successModal: `data-${widgetApi}__success-modal`,
  modal: `data-${widgetApi}__modal`,
  closeModalBtn: 'c-modal__close',
  errorModal: `data-${widgetApi}__error-modal`,
  submitBtn: `data-${widgetApi}__submit-btn`,
  cancelBtn: `data-${widgetApi}__cancel-btn`,
  postUrl: `data-${widgetApi}__post-url`,
  measurementTitle: `data-${widgetApi}__measurement-title`,
  measurementText: `data-${widgetApi}__measurement-text`,
  measurementContainer: `data-${widgetApi}__measurement-container`,
  measurementRow: `data-${widgetApi}__measurement-row`,
  heightTextBox: `data-${widgetApi}__height`,
  widthTextBox: `data-${widgetApi}__width`,
  lengthTextBox: `data-${widgetApi}__length`,
  weightTextBox: `data-${widgetApi}__weight`,
  packageTypeDropdown: `data-${widgetApi}__package-type`,
  packageTypeContainer: `data-${widgetApi}__package-type-container`,
  termsAndConditions: `data-${widgetApi}__terms-and-conditions-container`,
  petContainer: `data-${widgetApi}__pet-container`,
  animalTypeTextBox: `data-${widgetApi}__animal-type`,
  breedTextBox: `data-${widgetApi}__breed`,
  petMeasurementsSubtitle: `data-${widgetApi}__pet-measurements-subtitle`,
  containerMeasurementsSubtitle: `data-${widgetApi}__container-measurements-subtitle`,
  transportType: `data-${widgetApi}__transport-type`,
  transportationArea: `data-${widgetApi}__transportation-area`,
  containerPetType: `data-${widgetApi}__container-pet-type`,
  petFlightContainer: `data-${widgetApi}__pet-flight-container`,
  petTransferInfo: `data-${widgetApi}__pet-transfer-info`,
  petMeasurementsContainer: `data-${widgetApi}__pet-measurements-container`,
  petHeightTextBox: `data-${widgetApi}__pet-height`,
  petLengthTextBox: `data-${widgetApi}__pet-length`,
  petWeightTextBox: `data-${widgetApi}__pet-weight`,
  hasCarRental: `data-${widgetApi}__has-car-rental`,
  hasTransfer: `data-${widgetApi}__has-transfer`,
  medicalContainer: `data-${widgetApi}__medical-container`,
  medicalContainerNoFlight: `data-${widgetApi}__medical-container--no-flight`,
  medicalBagContainer: `data-${widgetApi}__medical-bag-container`,
  medicalType: `data-${widgetApi}__medical-type`,
  medicalCertificate: `data-${widgetApi}__medical-certificate-container`,
  isBringingMedicine: `data-${widgetApi}__is-bringing-medicine`,
  isNeedingExtraMedicineKilos: `data-${widgetApi}__is-needing-extra-medicine-kilos`,
  opiumLawInfo: `data-${widgetApi}__opium-law-info`,
  medicalBagMeasurementTitle: `data-${widgetApi}__medical-bag-measurement-title`,
  medicalBagType: `data-${widgetApi}__medical-bag-type`,
  wheelchairContainer: `data-${widgetApi}__wheelchair-container`,
  isParticipantWheelchairTransferRequired: `data-${widgetApi}__is-participant-wheelchair-transfer-required`,
  otherAccommodationSpecialRequirements: `data-${widgetApi}__other-accommodation-special-requirements`,
  accommodationSpecialRequirements: `data-${widgetApi}__accommodation-special-requirements`,
  isFoldedWheelchair: `data-${widgetApi}__is-folded-wheelchair`,
  wheelchairFoldedHeight: `data-${widgetApi}__wheelchair-folded-height`,
  wheelchairFoldedWidth: `data-${widgetApi}__wheelchair-folded-width`,
  wheelchairFoldedLength: `data-${widgetApi}__wheelchair-folded-length`,
  isParticipantWalking: `data-${widgetApi}__is-participant-walking`,
  isParticipantClimbingStairs: `data-${widgetApi}__is-participant-climbing-stairs`,
  isParticipantClimbingAirplaneStairs: `data-${widgetApi}__is-participant-climbing-airplane-stairs`,
  wheelchairNeeds: `data-${widgetApi}__wheelchair-needs`,
  isSeatReservationMade: `data-${widgetApi}__is-seat-reservation-made`,
  isOnlineCheckInCompleted: `data-${widgetApi}__is-online-check-in-completed`,
  foldedMeasurementsContainer: `data-${widgetApi}__folded-measurements-container`,
  electricWheelchairContainer: `data-${widgetApi}__electric-wheelchair-container`,
  electricContainer: `data-${widgetApi}__electric-fields-container`,
  isDryOrWetBattery: `data-${widgetApi}__is-dry-or-wet-battery`,
  brandOfBattery: `data-${widgetApi}__brand-of-battery`,
  typeOfBattery: `data-${widgetApi}__type-of-battery`,
  voltageOfBattery: `data-${widgetApi}__voltage-of-battery`,
  ampereHoursOfBattery: `data-${widgetApi}__ampere-hours-of-battery`,
  wattHoursOfBattery: `data-${widgetApi}__watt-hours-of-battery`,
  weightOfBattery: `data-${widgetApi}__weight-of-battery`,
  canBatteryBeDisconnected: `data-${widgetApi}__can-battery-be-disconnected`,
  isWheelchairTiltPossible: `data-${widgetApi}__is-wheelchair-tilt-possible`,
  medicalAssistanceContainer: `data-${widgetApi}__medical-assistance-container`,
  cpapCabinContainer: `data-${widgetApi}__cpap-cabin-container`,
  medicalEquipmentOnBoard: `data-${widgetApi}__medical-equipment-on-board-container`,
  isCpapHandLuggageCompliant: `data-${widgetApi}__is-cpap-hand-luggage-compliant`,
  isMedicalEquipmentNeededOnBoard: `data-${widgetApi}__is-medical-equipment-needed-on-board`,
  transportationAreaContainer: `data-${widgetApi}__transportation-area-container`,
  accoRequirementsContainer: `data-${widgetApi}__acco-requirements-container`,
  isMedicalAirportAssistanceNeeded: `data-${widgetApi}__is-medical-airport-assistance-needed`,
  medicalTypeContainer: `data-${widgetApi}__medical-type-container`,
  oxygenInfo: `data-${widgetApi}__oxygen-info`
}

export default class AccountSpecialLuggage {
  constructor (element) {
    if (!element) return
    this.element = element
    this.readyToValidate = false
    this.events = new EventEmitter()
    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
    this._getHtmlElements()
    this._getElementsApi()
    this._enableErrors()
    this._attachEvents()
    this._openOnLoad()
  }

  _getHtmlElements () {
    this.medicalLuggageText = this.element.querySelector(`[${widgetQueries.medicalLuggageText}]`)
    this.participantDropdown = this.element.querySelector(`[${widgetQueries.participantDropdown}]`)
    this.luggageTypeDropdown = this.element.querySelector(`[${widgetQueries.luggageTypeDropdown}]`)
    this.successModal = this.element.querySelector(`[${widgetQueries.successModal}]`)
    this.modal = this.element.querySelector(`[${widgetQueries.modal}]`)
    this.closeModalBtn = this.modal.querySelector(`.${widgetQueries.closeModalBtn}`)
    this.errorModal = this.element.querySelector(`[${widgetQueries.errorModal}]`)
    this.submitBtn = this.element.querySelector(`[${widgetQueries.submitBtn}]`)
    this.cancelBtn = this.element.querySelector(`[${widgetQueries.cancelBtn}]`)
    this.postUrl = this.element.getAttribute(widgetQueries.postUrl)
    this.measurementTitle = this.element.querySelector(`[${widgetQueries.measurementTitle}]`)
    this.measurementText = this.element.querySelector(`[${widgetQueries.measurementText}]`)
    this.measurementContainer = this.element.querySelector(`[${widgetQueries.measurementContainer}]`)
    this.measurementRows = this.element.querySelectorAll(`[${widgetQueries.measurementRow}]`)
    this.heightTextBox = this.element.querySelector(`[${widgetQueries.heightTextBox}]`)
    this.widthTextBox = this.element.querySelector(`[${widgetQueries.widthTextBox}]`)
    this.lengthTextBox = this.element.querySelector(`[${widgetQueries.lengthTextBox}]`)
    this.weightTextBox = this.element.querySelector(`[${widgetQueries.weightTextBox}]`)
    this.packageTypeDropdown = this.element.querySelector(`[${widgetQueries.packageTypeDropdown}]`)
    this.packageTypeContainer = this.element.querySelector(`[${widgetQueries.packageTypeContainer}]`)
    this.termsAndConditions = this.element.querySelector(`[${widgetQueries.termsAndConditions}]`)
    this.termsAndConditionsCheckbox = this.termsAndConditions.querySelector('.c-checkbox')
    this.termsAndConditionsInput = this.termsAndConditions.querySelector('input')
    this.petContainer = this.element.querySelector(`[${widgetQueries.petContainer}]`)
    this.animalTypeTextBox = this.element.querySelector(`[${widgetQueries.animalTypeTextBox}]`)
    this.breedTextBox = this.element.querySelector(`[${widgetQueries.breedTextBox}]`)
    this.petMeasurementsSubtitle = this.element.querySelector(`[${widgetQueries.petMeasurementsSubtitle}]`)
    this.containerMeasurementsSubtitle = this.element.querySelector(`[${widgetQueries.containerMeasurementsSubtitle}]`)
    this.transportType = this.element.getAttribute(`${widgetQueries.transportType}`)
    this.petFlightContainer = this.element.querySelector(`[${widgetQueries.petFlightContainer}]`)
    this.transportationArea = this.element.querySelector(`[${widgetQueries.transportationArea}]`)
    this.containerPetType = this.element.querySelector(`[${widgetQueries.containerPetType}]`)
    this.petTransferInfo = this.element.querySelector(`[${widgetQueries.petTransferInfo}]`)
    this.petMeasurementsContainer = this.element.querySelector(`[${widgetQueries.petMeasurementsContainer}]`)
    this.petHeightTextBox = this.element.querySelector(`[${widgetQueries.petHeightTextBox}]`)
    this.petLengthTextBox = this.element.querySelector(`[${widgetQueries.petLengthTextBox}]`)
    this.petWeightTextBox = this.element.querySelector(`[${widgetQueries.petWeightTextBox}]`)
    this.hasCarRental = this.element.hasAttribute(`${widgetQueries.hasCarRental}`)
    this.hasTransfer = this.element.hasAttribute(`${widgetQueries.hasTransfer}`)
    this.medicalContainer = this.element.querySelector(`[${widgetQueries.medicalContainer}]`)
    this.medicalContainerNoFlight = this.element.querySelector(`[${widgetQueries.medicalContainerNoFlight}]`)
    this.medicalBagContainer = this.element.querySelector(`[${widgetQueries.medicalBagContainer}]`)
    this.medicalType = this.element.querySelector(`[${widgetQueries.medicalType}]`)
    this.medicalCertificate = this.element.querySelector(`[${widgetQueries.medicalCertificate}]`)
    this.medicalCertificateCheckbox = this.medicalCertificate.querySelector('.c-checkbox')
    this.medicalCertificateInput = this.medicalCertificate.querySelector('input')
    this.isBringingMedicine = this.element.querySelector(`[${widgetQueries.isBringingMedicine}]`)
    this.isNeedingExtraMedicineKilos = this.element.querySelector(`[${widgetQueries.isNeedingExtraMedicineKilos}]`)
    this.opiumLawInfo = this.element.querySelector(`[${widgetQueries.opiumLawInfo}]`)
    this.medicalBagMeasurementTitle = this.element.querySelector(`[${widgetQueries.medicalBagMeasurementTitle}]`)
    this.medicalBagType = this.element.querySelector(`[${widgetQueries.medicalBagType}]`)
    this.wheelchairContainers = this.element.querySelectorAll(`[${widgetQueries.wheelchairContainer}]`)
    this.isParticipantWheelchairTransferRequired = this.element.querySelector(`[${widgetQueries.isParticipantWheelchairTransferRequired}]`)
    this.otherAccommodationSpecialRequirements = this.element.querySelector(`[${widgetQueries.otherAccommodationSpecialRequirements}]`)
    this.accommodationSpecialRequirements = this.element.querySelector(`[${widgetQueries.accommodationSpecialRequirements}]`)
    this.isFoldedWheelchair = this.element.querySelector(`[${widgetQueries.isFoldedWheelchair}]`)
    this.wheelchairFoldedHeight = this.element.querySelector(`[${widgetQueries.wheelchairFoldedHeight}]`)
    this.wheelchairFoldedWidth = this.element.querySelector(`[${widgetQueries.wheelchairFoldedWidth}]`)
    this.wheelchairFoldedLength = this.element.querySelector(`[${widgetQueries.wheelchairFoldedLength}]`)
    this.isParticipantWalking = this.element.querySelector(`[${widgetQueries.isParticipantWalking}]`)
    this.isParticipantClimbingStairs = this.element.querySelector(`[${widgetQueries.isParticipantClimbingStairs}]`)
    this.isParticipantClimbingAirplaneStairs = this.element.querySelector(`[${widgetQueries.isParticipantClimbingAirplaneStairs}]`)
    this.wheelchairNeeds = this.element.querySelector(`[${widgetQueries.wheelchairNeeds}]`)
    this.isSeatReservationMade = this.element.querySelector(`[${widgetQueries.isSeatReservationMade}]`)
    this.isOnlineCheckInCompleted = this.element.querySelector(`[${widgetQueries.isOnlineCheckInCompleted}]`)
    this.foldedMeasurementsContainer = this.element.querySelector(`[${widgetQueries.foldedMeasurementsContainer}]`)
    this.electricWheelchairContainer = this.element.querySelector(`[${widgetQueries.electricWheelchairContainer}]`)
    this.electricContainer = this.element.querySelector(`[${widgetQueries.electricContainer}]`)
    this.isDryOrWetBattery = this.element.querySelector(`[${widgetQueries.isDryOrWetBattery}]`)
    this.brandOfBattery = this.element.querySelector(`[${widgetQueries.brandOfBattery}]`)
    this.typeOfBattery = this.element.querySelector(`[${widgetQueries.typeOfBattery}]`)
    this.voltageOfBattery = this.element.querySelector(`[${widgetQueries.voltageOfBattery}]`)
    this.ampereHoursOfBattery = this.element.querySelector(`[${widgetQueries.ampereHoursOfBattery}]`)
    this.wattHoursOfBattery = this.element.querySelector(`[${widgetQueries.wattHoursOfBattery}]`)
    this.weightOfBattery = this.element.querySelector(`[${widgetQueries.weightOfBattery}]`)
    this.canBatteryBeDisconnected = this.element.querySelector(`[${widgetQueries.canBatteryBeDisconnected}]`)
    this.isWheelchairTiltPossible = this.element.querySelector(`[${widgetQueries.isWheelchairTiltPossible}]`)
    this.medicalAssistanceContainer = this.element.querySelector(`[${widgetQueries.medicalAssistanceContainer}]`)
    this.medicalEquipmentOnBoard = this.element.querySelector(`[${widgetQueries.medicalEquipmentOnBoard}]`)
    this.cpapCabinContainer = this.element.querySelector(`[${widgetQueries.cpapCabinContainer}]`)
    this.isCpapHandLuggageCompliant = this.element.querySelector(`[${widgetQueries.isCpapHandLuggageCompliant}]`)
    this.isMedicalEquipmentNeededOnBoard = this.element.querySelector(`[${widgetQueries.isMedicalEquipmentNeededOnBoard}]`)
    this.transportationAreaContainer = this.element.querySelector(`[${widgetQueries.transportationAreaContainer}]`)
    this.accoRequirementsContainer = this.element.querySelector(`[${widgetQueries.accoRequirementsContainer}]`)
    this.isMedicalAirportAssistanceNeeded = this.element.querySelector(`[${widgetQueries.isMedicalAirportAssistanceNeeded}]`)
    this.medicalTypeContainer = this.element.querySelector(`[${widgetQueries.medicalTypeContainer}]`)
    this.oxygenInfo = this.element.querySelector(`[${widgetQueries.oxygenInfo}]`)
  }

  _getElementsApi () {
    this.modalApi = this.modal['c-modal']
    this.closeModalBtnApi = this.closeModalBtn['c-btn']
    this.successModalApi = this.successModal['c-modal']
    this.errorModalApi = this.errorModal['c-modal']
    this.luggageTypeDropdownApi = this.luggageTypeDropdown['c-dropdown']
    this.participantDropdownApi = this.participantDropdown['c-dropdown']
    this.submitBtnApi = this.submitBtn['c-btn']
    this.cancelBtnApi = this.cancelBtn['c-btn']
    this.heightTextBoxApi = this.heightTextBox['c-textbox']
    this.widthTextBoxApi = this.widthTextBox['c-textbox']
    this.lengthTextBoxApi = this.lengthTextBox['c-textbox']
    this.weightTextBoxApi = this.weightTextBox['c-textbox']
    this.packageTypeDropdownApi = this.packageTypeDropdown['c-dropdown']
    this.animalTypeTextBoxApi = this.animalTypeTextBox['c-textbox']
    this.breedTextBoxApi = this.breedTextBox['c-textbox']
    this.transportationAreaApi = this.transportationArea['c-dropdown']
    this.containerPetTypeApi = this.containerPetType['c-dropdown']
    this.petHeightTextBoxApi = this.petHeightTextBox['c-textbox']
    this.petLengthTextBoxApi = this.petLengthTextBox['c-textbox']
    this.petWeightTextBoxApi = this.petWeightTextBox['c-textbox']
    this.medicalTypeApi = this.medicalType['c-dropdown']
    this.isBringingMedicineApi = this.isBringingMedicine['c-choice-list']
    this.isNeedingExtraMedicineKilosApi = this.isNeedingExtraMedicineKilos['c-choice-list']
    this.medicalBagTypeApi = this.medicalBagType['c-textbox']
    this.accommodationSpecialRequirementsApi = this.accommodationSpecialRequirements['c-dropdown-multiple']
    this.isFoldedWheelchairApi = this.isFoldedWheelchair['c-choice-list']
    this.wheelchairFoldedHeightApi = this.wheelchairFoldedHeight['c-textbox']
    this.wheelchairFoldedWidthApi = this.wheelchairFoldedWidth['c-textbox']
    this.wheelchairFoldedLengthApi = this.wheelchairFoldedLength['c-textbox']
    this.isParticipantWalkingApi = this.isParticipantWalking['c-choice-list']
    this.isParticipantClimbingStairsApi = this.isParticipantClimbingStairs['c-choice-list']
    this.isParticipantClimbingAirplaneStairsApi = this.isParticipantClimbingAirplaneStairs['c-choice-list']
    this.wheelchairNeedsApi = this.wheelchairNeeds['c-choice-list']
    this.isSeatReservationMadeApi = this.isSeatReservationMade['c-choice-list']
    this.isOnlineCheckInCompletedApi = this.isOnlineCheckInCompleted['c-choice-list']
    this.isParticipantWheelchairTransferRequiredApi = this.isParticipantWheelchairTransferRequired['c-choice-list']
    this.otherAccommodationSpecialRequirementsApi = this.otherAccommodationSpecialRequirements['c-textbox']
    this.isDryOrWetBatteryApi = this.isDryOrWetBattery['c-choice-list']
    this.brandOfBatteryApi = this.brandOfBattery['c-textbox']
    this.typeOfBatteryApi = this.typeOfBattery['c-textbox']
    this.voltageOfBatteryApi = this.voltageOfBattery['c-textbox']
    this.ampereHoursOfBatteryApi = this.ampereHoursOfBattery['c-textbox']
    this.wattHoursOfBatteryApi = this.wattHoursOfBattery['c-textbox']
    this.weightOfBatteryApi = this.weightOfBattery['c-textbox']
    this.canBatteryBeDisconnectedApi = this.canBatteryBeDisconnected['c-choice-list']
    this.isWheelchairTiltPossibleApi = this.isWheelchairTiltPossible['c-choice-list']
    this.isCpapHandLuggageCompliantApi = this.isCpapHandLuggageCompliant['c-choice-list']
    this.isMedicalEquipmentNeededOnBoardApi = this.isMedicalEquipmentNeededOnBoard['c-choice-list']
    this.isMedicalAirportAssistanceNeededApi = this.isMedicalAirportAssistanceNeeded['c-choice-list']
  }

  _attachEvents () {
    this.modalApi.events.on('open', () => {
      this.events.emit(accountSpecialLuggageEvents.OPEN)
    })
    this._attachTextBoxEvents()
    this._attachDropdownEvents()
    this.luggageTypeDropdownApi.events.on('change', (value) => {
      this.luggageTypeDropdownApi.validate()
      this._handleLuggageTypeChange(value)
    })

    this.submitBtn.addEventListener('click', this._submitSpecialLuggage.bind(this))
    this.modalApi.events.on('closed', this._resetForm, this)
    this.termsAndConditionsInput.addEventListener('change', () => {
      if (this.readyToValidate) this._validateCheckbox(this.termsAndConditionsInput, this.termsAndConditionsCheckbox)
    })

    this.medicalCertificateInput.addEventListener('change', () => {
      if (this.readyToValidate) this._validateCheckbox(this.medicalCertificateInput, this.medicalCertificateCheckbox)
    })

    this.medicalTypeApi.events.on('change', () => {
      this._handleLuggageTypeChangeWithCurrentLuggageType()
    })

    this.isBringingMedicineApi.events.on('changeOptions', () => {
      const isBringingMedicine = this.isBringingMedicineApi.getSelectedValues()[0] === 'yes'
      this._toggleVisibility(this.opiumLawInfo, isBringingMedicine)
    })

    this.accommodationSpecialRequirementsApi.events.on('changeOptions', () => {
      const hasOtherValue = this.accommodationSpecialRequirementsApi.getSelectedValues().includes('other')
      this._toggleVisibility(this.otherAccommodationSpecialRequirements, hasOtherValue)
    })

    this.isFoldedWheelchairApi.events.on('changeOptions', () => {
      const isFoldedWheelchair = this.isFoldedWheelchairApi.getSelectedValues()[0] === 'yes'
      this._toggleVisibility(this.foldedMeasurementsContainer, isFoldedWheelchair)
    })

    this._setupLuggageTypeChangeListener(this.isNeedingExtraMedicineKilosApi, 'changeOptions')
    this._setupLuggageTypeChangeListener(this.isCpapHandLuggageCompliantApi, 'changeOptions')
    this._setupLuggageTypeChangeListener(this.transportationAreaApi, 'change')
  }

  _setupLuggageTypeChangeListener (apiObject, eventName) {
    apiObject.events.on(eventName, () => {
      this._handleLuggageTypeChangeWithCurrentLuggageType()
    })
  }

  _handleLuggageTypeChangeWithCurrentLuggageType () {
    const luggageTypeValue = this.luggageTypeDropdownApi.getValue()
    this._handleLuggageTypeChange(luggageTypeValue)
  }

  _attachTextBoxEvents () {
    ['heightTextBoxApi',
      'widthTextBoxApi',
      'lengthTextBoxApi',
      'weightTextBoxApi',
      'animalTypeTextBoxApi',
      'breedTextBoxApi',
      'petHeightTextBoxApi',
      'petLengthTextBoxApi',
      'petWeightTextBoxApi',
      'medicalBagTypeApi',
      'wheelchairFoldedHeightApi',
      'wheelchairFoldedWidthApi',
      'wheelchairFoldedLengthApi',
      'otherAccommodationSpecialRequirementsApi',
      'brandOfBatteryApi',
      'typeOfBatteryApi',
      'voltageOfBatteryApi',
      'ampereHoursOfBatteryApi',
      'wattHoursOfBatteryApi',
      'weightOfBatteryApi'].forEach(apiKey => {
      this[apiKey].events.on('keyup', () => {
        if (this.readyToValidate) this[apiKey].validate()
      })
    })
  }

  _attachDropdownEvents () {
    ['participantDropdownApi',
      'transportationAreaApi',
      'containerPetTypeApi',
      'packageTypeDropdownApi',
      'medicalTypeApi'].forEach(apiKey => {
      this[apiKey].events.on('change', () => {
        this[apiKey].validate()
      })
    })
  }

  _handleLuggageTypeChange (value) {
    this._updateTitleBasedOnSelection()
    this._toggleLuggageContent(value)
  }

  _updateTitleBasedOnSelection () {
    const options = this.luggageTypeDropdownApi.getProp('options')
    const selectedOption = options.find(option => option.selected)
    if (selectedOption) {
      this.measurementTitle.innerHTML = selectedOption.text
    }
  }

  _toggleLuggageContent (luggageType) {
    const isMedical = luggageType === luggageTypeOptions.medical
    const isBike = luggageType === luggageTypeOptions.bike
    const isPet = luggageType === luggageTypeOptions.pet
    const isFlight = this.transportType === transportTypeOptions.flight
    const isMedicalBag = this.medicalTypeApi.getValue() === medicalTypeOptions.bag
    const medicalTypeValue = this.medicalTypeApi.getValue()
    const isWheelchair = [medicalTypeOptions.manualWheelchair, medicalTypeOptions.electricWheelchair].includes(medicalTypeValue)
    const isCpap = medicalTypeValue === medicalTypeOptions.cpap
    const isOtherMedicalEquipment = medicalTypeValue === medicalTypeOptions.otherMedicalEquipment

    this._toggleVisibility(this.measurementTitle, !!luggageType)
    this._toggleVisibility(this.measurementContainer, !!luggageType)
    this._toggleVisibility(this.measurementText, !!luggageType && (!isPet || isFlight) && !isMedical)
    this._toggleVisibility(this.packageTypeContainer, isBike)
    this._toggleVisibility(this.transportationAreaContainer, (isPet && isFlight) || (isMedical && (isCpap || isOtherMedicalEquipment)))
    this._togglePetTypeContent(isPet, isFlight)
    this._toggleMedicalTypeContent(isMedical, isFlight)

    this.measurementRows.forEach(row => {
      let isNeedingExtraMedicineKilos
      switch (luggageType) {
        case luggageTypeOptions.medical:
          isNeedingExtraMedicineKilos = this.isNeedingExtraMedicineKilosApi.getSelectedValues()[0] === 'yes'
          this._toggleVisibility(row, (isMedicalBag && isNeedingExtraMedicineKilos) ||
            isWheelchair ||
            this._isCpapMeasurementsAndElectricFieldsRequired(isCpap) ||
            isOtherMedicalEquipment)
          break
        case luggageTypeOptions.pet:
          this._toggleVisibility(row, isFlight)
          break
        default:
          this._toggleVisibility(row, true)
          break
      }
    })
  }

  _togglePetTypeContent (isPet, isFlight) {
    this._toggleVisibility(this.petContainer, isPet)
    this._toggleVisibility(this.containerMeasurementsSubtitle, isPet && isFlight)
    this._toggleVisibility(this.petFlightContainer, isPet && isFlight)
    this._toggleVisibility(this.petTransferInfo, isPet && isFlight)
    this._toggleVisibility(this.petMeasurementsContainer, this.hasCarRental && isPet)
  }

  _toggleMedicalTypeContent (isMedical, isFlight) {
    const medicalType = this.medicalTypeApi.getValue()
    this.submitBtnApi.setProp('disabled', isMedical && !isFlight)
    this._toggleVisibility(this.medicalContainer, isMedical && isFlight)
    this._toggleVisibility(this.medicalContainerNoFlight, isMedical && !isFlight)
    this._toggleMedicalTypeChanged(isMedical, medicalType)
  }

  _toggleMedicalTypeChanged (isMedical, medicalType) {
    const isMedicalBag = medicalType === medicalTypeOptions.bag
    const isOtherMedical = medicalType === medicalTypeOptions.other
    const isWheelchair = [medicalTypeOptions.manualWheelchair, medicalTypeOptions.electricWheelchair].includes(medicalType)
    const isElectricWheelchair = medicalType === medicalTypeOptions.electricWheelchair
    const isCpap = medicalType === medicalTypeOptions.cpap
    const isCabinTransportationArea = this.transportationAreaApi.getValue() === 'cabin'
    const isOtherMedicalEquipment = medicalType === medicalTypeOptions.otherMedicalEquipment
    const isNeedingExtraMedicineKilos = this.isNeedingExtraMedicineKilosApi.getSelectedValues()[0] === 'yes'
    const isOxygen = medicalType === medicalTypeOptions.oxygen

    this._toggleVisibility(this.medicalBagContainer, isMedical && isMedicalBag)
    this._toggleVisibility(this.medicalBagMeasurementTitle, isMedical && isMedicalBag && isNeedingExtraMedicineKilos)
    this._toggleVisibility(this.medicalTypeContainer, isMedical && (isMedicalBag || isOtherMedicalEquipment || isOtherMedical))
    this._toggleVisibility(this.medicalCertificate, isMedical && (isMedicalBag || isCpap || isOtherMedicalEquipment || isOxygen))
    this._toggleVisibility(this.medicalLuggageText, isMedical && isOtherMedical)
    this._toggleVisibility(this.isParticipantWheelchairTransferRequired, isWheelchair && this.hasTransfer)
    this._toggleVisibility(this.termsAndConditions, !isMedical || isMedicalBag || isWheelchair || isCpap || isOtherMedicalEquipment || isOxygen)
    this._toggleVisibility(this.electricWheelchairContainer, isMedical && isElectricWheelchair)
    this._toggleVisibility(this.electricContainer, isMedical && (isElectricWheelchair || this._isCpapMeasurementsAndElectricFieldsRequired(isCpap) || isOtherMedicalEquipment))
    this._toggleVisibility(this.accoRequirementsContainer, isMedical && (isWheelchair || isCpap || isOtherMedicalEquipment || isOxygen))
    this._toggleVisibility(this.medicalAssistanceContainer, isMedical && (isCpap || isOtherMedicalEquipment || isOxygen))
    this._toggleVisibility(this.cpapCabinContainer, isMedical && isCpap && isCabinTransportationArea)
    this._toggleVisibility(this.medicalEquipmentOnBoard, isMedical && (isCabinTransportationArea && (isCpap || isOtherMedicalEquipment)))
    this._toggleVisibility(this.oxygenInfo, isMedical && isOxygen)
    this.wheelchairContainers.forEach(container => {
      this._toggleVisibility(container, isMedical && isWheelchair)
    })
    this._updateBatteryFieldsStatus(isMedical, isOtherMedicalEquipment)
  }

  _updateBatteryFieldsStatus (isMedical, isOtherMedicalEquipment) {
    ['voltageOfBattery', 'ampereHoursOfBattery', 'wattHoursOfBattery'].forEach(field => {
      const value = this[`${field}Api`].getProp('value')
      this[`${field}Api`].setProp('required', !isOtherMedicalEquipment)
      if (isMedical && isOtherMedicalEquipment) {
        this._resetField(this[field], this[`${field}Api`], 'textbox')
      }
      this[`${field}Api`].setProp('value', value)
    })
  }

  _isCpapMeasurementsAndElectricFieldsRequired (isCpap) {
    const isHoldTransportationArea = this.transportationAreaApi.getValue() === 'hold-luggage'
    const isCabinTransportationArea = this.transportationAreaApi.getValue() === 'cabin'
    const isNotCpapHandLuggageCompliant = this.isCpapHandLuggageCompliantApi.getSelectedValues()[0] === 'no'
    return isCpap && (isHoldTransportationArea || (isCabinTransportationArea && isNotCpapHandLuggageCompliant))
  }

  _toggleVisibility (element, isVisible, classToToggle = 'u-hidden') {
    if (isVisible) {
      element.classList.remove(classToToggle)
    } else {
      element.classList.add(classToToggle)
    }
  }

  _getBody () {
    const baseBody = {
      participant: this.participantDropdownApi.getValue(),
      luggageType: this.luggageTypeDropdownApi.getValue()
    }

    switch (baseBody.luggageType) {
      case luggageTypeOptions.medical:
        return this._getMedicalBody(baseBody)
      case luggageTypeOptions.pet:
        return this._getPetBody(baseBody)
      case luggageTypeOptions.bike:
        return this._getBikeBody(baseBody)
      default:
        return this._getCommonDimensions(baseBody)
    }
  }

  _getMedicalBody (baseBody) {
    let medicalBody = {
      ...baseBody,
      medicalType: this.medicalTypeApi.getValue()
    }
    if (medicalBody.medicalType === medicalTypeOptions.bag) {
      medicalBody = {
        ...medicalBody,
        isBringingMedicine: this.isBringingMedicineApi.getSelectedValues()[0] === 'yes',
        isNeedingExtraMedicineKilos: this.isNeedingExtraMedicineKilosApi.getSelectedValues()[0] === 'yes',
        medicalBagType: this.medicalBagTypeApi.getProp('value')
      }
      if (medicalBody.isNeedingExtraMedicineKilos) {
        medicalBody = {
          ...medicalBody,
          ...this._getCommonDimensions()
        }
      }
    }

    if (medicalBody.medicalType === medicalTypeOptions.cpap) {
      medicalBody = {
        ...medicalBody,
        transportationArea: this.transportationAreaApi.getValue(),
        accommodationSpecialRequirements: this.accommodationSpecialRequirementsApi.getSelectedValues(),
        isMedicalAirportAssistanceNeeded: this.isMedicalAirportAssistanceNeededApi.getSelectedValues()[0] === 'yes'
      }

      if (this.transportationAreaApi.getValue() === 'cabin') {
        medicalBody = {
          ...medicalBody,
          isCpapHandLuggageCompliant: this.isCpapHandLuggageCompliantApi.getSelectedValues()[0] === 'yes',
          isMedicalEquipmentNeededOnBoard: this.isMedicalEquipmentNeededOnBoardApi.getSelectedValues()[0] === 'yes'
        }
      }

      if (this._isCpapMeasurementsAndElectricFieldsRequired(true)) {
        medicalBody = {
          ...medicalBody,
          ...this._getCommonDimensions(),
          brand: this.brandOfBatteryApi.getProp('value'),
          voltage: this.voltageOfBatteryApi.getProp('value'),
          ampereHours: this.ampereHoursOfBatteryApi.getProp('value'),
          wattHours: this.wattHoursOfBatteryApi.getProp('value')
        }
      }

      if (this.accommodationSpecialRequirementsApi.getSelectedValues().includes('other')) {
        medicalBody = {
          ...medicalBody,
          otherAccommodationSpecialRequirements: this.otherAccommodationSpecialRequirementsApi.getProp('value')
        }
      }
    }

    if (medicalBody.medicalType === medicalTypeOptions.oxygen) {
      medicalBody = {
        ...medicalBody,
        isMedicalAirportAssistanceNeeded: this.isMedicalAirportAssistanceNeededApi.getSelectedValues()[0] === 'yes',
        accommodationSpecialRequirements: this.accommodationSpecialRequirementsApi.getSelectedValues()
      }

      if (this.accommodationSpecialRequirementsApi.getSelectedValues().includes('other')) {
        medicalBody = {
          ...medicalBody,
          otherAccommodationSpecialRequirements: this.otherAccommodationSpecialRequirementsApi.getProp('value')
        }
      }
    }

    if (medicalBody.medicalType === medicalTypeOptions.otherMedicalEquipment) {
      medicalBody = {
        ...medicalBody,
        medicalBagType: this.medicalBagTypeApi.getProp('value'),
        transportationArea: this.transportationAreaApi.getValue(),
        isMedicalAirportAssistanceNeeded: this.isMedicalAirportAssistanceNeededApi.getSelectedValues()[0] === 'yes',
        ...this._getCommonDimensions(),
        brand: this.brandOfBatteryApi.getProp('value'),
        voltage: this.voltageOfBatteryApi.getProp('value'),
        ampereHours: this.ampereHoursOfBatteryApi.getProp('value'),
        wattHours: this.wattHoursOfBatteryApi.getProp('value'),
        accommodationSpecialRequirements: this.accommodationSpecialRequirementsApi.getSelectedValues()
      }

      if (this.transportationAreaApi.getValue() === 'cabin') {
        medicalBody = {
          ...medicalBody,
          isMedicalEquipmentNeededOnBoard: this.isMedicalEquipmentNeededOnBoardApi.getSelectedValues()[0] === 'yes'
        }
      }

      if (this.accommodationSpecialRequirementsApi.getSelectedValues().includes('other')) {
        medicalBody = {
          ...medicalBody,
          otherAccommodationSpecialRequirements: this.otherAccommodationSpecialRequirementsApi.getProp('value')
        }
      }
    }

    if (medicalBody.medicalType === medicalTypeOptions.other) {
      medicalBody = {
        ...medicalBody,
        medicalBagType: this.medicalBagTypeApi.getProp('value')
      }
    }

    if ([medicalTypeOptions.manualWheelchair, medicalTypeOptions.electricWheelchair].includes(medicalBody.medicalType)) {
      medicalBody = {
        ...medicalBody,
        ...this._getCommonDimensions(),
        isFoldedWheelchair: this.isFoldedWheelchairApi.getSelectedValues()[0] === 'yes',
        isParticipantWalking: this.isParticipantWalkingApi.getSelectedValues()[0] === 'yes',
        isParticipantClimbingStairs: this.isParticipantClimbingStairsApi.getSelectedValues()[0] === 'yes',
        isParticipantClimbingAirplaneStairs: this.isParticipantClimbingAirplaneStairsApi.getSelectedValues()[0] === 'yes',
        wheelchairNeeds: this.wheelchairNeedsApi.getSelectedValues()[0],
        accommodationSpecialRequirements: this.accommodationSpecialRequirementsApi.getSelectedValues(),
        isSeatReservationMade: this.isSeatReservationMadeApi.getSelectedValues()[0] === 'yes',
        isOnlineCheckInCompleted: this.isOnlineCheckInCompletedApi.getSelectedValues()[0] === 'yes'
      }

      if (this.accommodationSpecialRequirementsApi.getSelectedValues().includes('other')) {
        medicalBody = {
          ...medicalBody,
          otherAccommodationSpecialRequirements: this.otherAccommodationSpecialRequirementsApi.getProp('value')
        }
      }

      if (this.isFoldedWheelchairApi.getSelectedValues()[0] === 'yes') {
        medicalBody = {
          ...medicalBody,
          wheelchairFoldedHeight: this.wheelchairFoldedHeightApi.getProp('value'),
          wheelchairFoldedWidth: this.wheelchairFoldedWidthApi.getProp('value'),
          wheelchairFoldedLength: this.wheelchairFoldedLengthApi.getProp('value')
        }
      }

      if (this.hasTransfer) {
        medicalBody = {
          ...medicalBody,
          isParticipantWheelchairTransferRequired: this.isParticipantWheelchairTransferRequiredApi.getSelectedValues()[0] === 'yes'
        }
      }

      if (medicalBody.medicalType === medicalTypeOptions.electricWheelchair) {
        medicalBody = {
          ...medicalBody,
          isDryOrWetBattery: this.isDryOrWetBatteryApi.getSelectedValues()[0],
          brand: this.brandOfBatteryApi.getProp('value'),
          typeOfBattery: this.typeOfBatteryApi.getProp('value'),
          voltage: this.voltageOfBatteryApi.getProp('value'),
          ampereHours: this.ampereHoursOfBatteryApi.getProp('value'),
          wattHours: this.wattHoursOfBatteryApi.getProp('value'),
          weightOfBattery: this.weightOfBatteryApi.getProp('value'),
          canBatteryBeDisconnected: this.canBatteryBeDisconnectedApi.getSelectedValues()[0] === 'yes',
          isWheelchairTiltPossible: this.isWheelchairTiltPossibleApi.getSelectedValues()[0] === 'yes'
        }
      }
    }
    return medicalBody
  }

  _getBikeBody (baseBody) {
    return {
      ...this._getCommonDimensions(baseBody),
      packageType: this.packageTypeDropdownApi.getValue()
    }
  }

  _getPetBody (baseBody) {
    let petBody = {
      ...baseBody,
      animalType: this.animalTypeTextBoxApi.getProp('value'),
      breed: this.breedTextBoxApi.getProp('value')
    }
    if (this.transportType === transportTypeOptions.flight) {
      petBody = {
        ...petBody,
        ...this._getCommonDimensions(),
        transportationArea: this.transportationAreaApi.getValue(),
        containerPetType: this.containerPetTypeApi.getValue()
      }
    }
    if (this.hasCarRental) {
      petBody = {
        ...petBody,
        petHeight: this.petHeightTextBoxApi.getProp('value'),
        petLength: this.petLengthTextBoxApi.getProp('value'),
        petWeight: this.petWeightTextBoxApi.getProp('value')
      }
    }
    return petBody
  }

  _getCommonDimensions (baseBody) {
    return {
      ...baseBody,
      height: this.heightTextBoxApi.getProp('value'),
      length: this.lengthTextBoxApi.getProp('value'),
      weight: this.weightTextBoxApi.getProp('value'),
      width: this.widthTextBoxApi.getProp('value')
    }
  }

  async _submitSpecialLuggage () {
    this.readyToValidate = true
    if (!this._validateFields()) return
    this._modalLoading(true)
    const body = this._getBody()
    const { success, response } = await apiCaller(this.postUrl, { method: 'POST', body })
    if (success && response) {
      this.successModalApi.open()
      this.events.emit(accountSpecialLuggageEvents.SUBMITTED, body.luggageType)
    } else {
      this.errorModalApi.open()
      this.events.emit(accountSpecialLuggageEvents.ERROR_SUBMITTING)
    }
    this._modalLoading(false)
    this.modalApi.close()
  }

  _modalLoading (isLoading) {
    const toggleDisabledApis = [
      this.submitBtnApi,
      this.closeModalBtnApi,
      this.cancelBtnApi,
      this.luggageTypeDropdownApi,
      this.participantDropdownApi,
      this.heightTextBoxApi,
      this.widthTextBoxApi,
      this.lengthTextBoxApi,
      this.weightTextBoxApi,
      this.animalTypeTextBoxApi,
      this.breedTextBoxApi,
      this.transportationAreaApi,
      this.containerPetTypeApi,
      this.petHeightTextBoxApi,
      this.petLengthTextBoxApi,
      this.petWeightTextBoxApi,
      this.packageTypeDropdownApi,
      this.medicalTypeApi,
      this.medicalBagTypeApi,
      this.wheelchairFoldedHeightApi,
      this.wheelchairFoldedWidthApi,
      this.wheelchairFoldedLengthApi,
      this.accommodationSpecialRequirementsApi,
      this.otherAccommodationSpecialRequirementsApi,
      this.brandOfBatteryApi,
      this.typeOfBatteryApi,
      this.voltageOfBatteryApi,
      this.ampereHoursOfBatteryApi,
      this.wattHoursOfBatteryApi,
      this.weightOfBatteryApi
    ]

    const toggleComponent = (component, isLoading) => {
      isLoading ? component.disableComponent() : component.enableComponent()
    }

    toggleComponent(this.isBringingMedicineApi, isLoading)
    toggleComponent(this.isNeedingExtraMedicineKilosApi, isLoading)
    toggleComponent(this.isFoldedWheelchairApi, isLoading)
    toggleComponent(this.isParticipantWalkingApi, isLoading)
    toggleComponent(this.isParticipantClimbingStairsApi, isLoading)
    toggleComponent(this.isParticipantClimbingAirplaneStairsApi, isLoading)
    toggleComponent(this.isParticipantWheelchairTransferRequiredApi, isLoading)
    toggleComponent(this.wheelchairNeedsApi, isLoading)
    toggleComponent(this.isSeatReservationMadeApi, isLoading)
    toggleComponent(this.isOnlineCheckInCompletedApi, isLoading)
    toggleComponent(this.isDryOrWetBatteryApi, isLoading)
    toggleComponent(this.canBatteryBeDisconnectedApi, isLoading)
    toggleComponent(this.isWheelchairTiltPossibleApi, isLoading)
    toggleComponent(this.isCpapHandLuggageCompliantApi, isLoading)
    toggleComponent(this.isMedicalEquipmentNeededOnBoardApi, isLoading)
    toggleComponent(this.isMedicalAirportAssistanceNeededApi, isLoading)

    this.submitBtnApi.setLoading(isLoading)
    toggleDisabledApis.forEach(api => api.setProp('disabled', isLoading))
    this.modalApi.setProp('closable', !isLoading)
    this.termsAndConditionsCheckbox.classList.toggle('is-disabled', isLoading)
    this.medicalCertificateCheckbox.classList.toggle('is-disabled', isLoading)
  }

  _validateFields () {
    const luggageType = this.luggageTypeDropdownApi.getValue()
    const isParticipantValid = this.participantDropdownApi.validate().isValid
    const medicalTypeValue = this.medicalTypeApi.getValue()

    if (!this.luggageTypeDropdownApi.validate().isValid || !luggageType) return false

    const isValidationPassed = this._validateLuggageType(luggageType)

    const isCheckboxValidationRequired = luggageType !== luggageTypeOptions.medical || (medicalTypeValue !== medicalTypeOptions.other && medicalTypeValue)

    if (isCheckboxValidationRequired) {
      return this._validateCheckbox(this.termsAndConditionsInput, this.termsAndConditionsCheckbox) && isParticipantValid && isValidationPassed
    } else {
      return isParticipantValid && isValidationPassed
    }
  }

  _validateLuggageType (luggageType) {
    switch (luggageType) {
      case luggageTypeOptions.medical:
        return this._validateMedicalLuggage()
      case luggageTypeOptions.pet:
        return this._validatePetLuggage()
      case luggageTypeOptions.bike:
        return this._validateBikeLuggage()
      default:
        return this._validateGeneralLuggage()
    }
  }

  _validateMedicalLuggage () {
    let validations = [
      this.medicalTypeApi.validate()
    ]

    const medicalTypeValue = this.medicalTypeApi.getValue()
    if (!medicalTypeValue || medicalTypeValue === medicalTypeOptions.other) {
      validations = validations.concat(
        this.medicalBagTypeApi.validate()
      )
      return validations.every(validation => validation.isValid)
    }

    if (medicalTypeValue === medicalTypeOptions.otherMedicalEquipment) {
      validations = validations.concat(
        this._validateMeasurements(),
        this.transportationAreaApi.validate(),
        this.medicalBagTypeApi.validate(),
        this.brandOfBatteryApi.validate(),
        this.isMedicalAirportAssistanceNeededApi.validate()
      )

      if (this.transportationAreaApi.getValue() === 'cabin') {
        validations = validations.concat(
          this.isMedicalEquipmentNeededOnBoardApi.validate()
        )
      }

      if (this.accommodationSpecialRequirementsApi.getSelectedValues().includes('other')) {
        validations = validations.concat(this.otherAccommodationSpecialRequirementsApi.validate())
      }

      const areValidationsValid = validations.every(validation => validation.isValid)
      const isMedicalCheckboxValid = this._validateCheckbox(this.medicalCertificateInput, this.medicalCertificateCheckbox)
      return areValidationsValid && isMedicalCheckboxValid
    }

    if (medicalTypeValue === medicalTypeOptions.oxygen) {
      validations = validations.concat(
        this.isMedicalAirportAssistanceNeededApi.validate()
      )

      if (this.accommodationSpecialRequirementsApi.getSelectedValues().includes('other')) {
        validations = validations.concat(this.otherAccommodationSpecialRequirementsApi.validate())
      }

      const areValidationsValid = validations.every(validation => validation.isValid)
      const isMedicalCheckboxValid = this._validateCheckbox(this.medicalCertificateInput, this.medicalCertificateCheckbox)
      return areValidationsValid && isMedicalCheckboxValid
    }

    if (medicalTypeValue === medicalTypeOptions.cpap) {
      validations = validations.concat(
        this.transportationAreaApi.validate(),
        this.isMedicalAirportAssistanceNeededApi.validate()
      )

      if (this.transportationAreaApi.getValue() === 'cabin') {
        validations = validations.concat(
          this.isCpapHandLuggageCompliantApi.validate(),
          this.isMedicalEquipmentNeededOnBoardApi.validate()
        )
      }

      if (this._isCpapMeasurementsAndElectricFieldsRequired(true)) {
        validations = validations.concat(
          this._validateMeasurements(),
          this.brandOfBatteryApi.validate(),
          this.voltageOfBatteryApi.validate(),
          this.ampereHoursOfBatteryApi.validate(),
          this.wattHoursOfBatteryApi.validate()
        )
      }

      if (this.accommodationSpecialRequirementsApi.getSelectedValues().includes('other')) {
        validations = validations.concat(this.otherAccommodationSpecialRequirementsApi.validate())
      }

      const areValidationsValid = validations.every(validation => validation.isValid)
      const isMedicalCheckboxValid = this._validateCheckbox(this.medicalCertificateInput, this.medicalCertificateCheckbox)
      return areValidationsValid && isMedicalCheckboxValid
    }

    if (medicalTypeValue === medicalTypeOptions.bag) {
      validations = validations.concat(
        this.isBringingMedicineApi.validate(),
        this.isNeedingExtraMedicineKilosApi.validate(),
        this.medicalBagTypeApi.validate()
      )
      if (this.isNeedingExtraMedicineKilosApi.getSelectedValues()[0] === 'yes') {
        validations = validations.concat(
          this._validateMeasurements()
        )
      }
      const areValidationsValid = validations.every(validation => validation.isValid)
      const isMedicalCheckboxValid = this._validateCheckbox(this.medicalCertificateInput, this.medicalCertificateCheckbox)
      return areValidationsValid && isMedicalCheckboxValid
    }

    if ([medicalTypeOptions.manualWheelchair, medicalTypeOptions.electricWheelchair].includes(medicalTypeValue)) {
      validations = validations.concat(
        this.isFoldedWheelchairApi.validate(),
        this.isParticipantWalkingApi.validate(),
        this.isParticipantClimbingStairsApi.validate(),
        this.isParticipantClimbingAirplaneStairsApi.validate(),
        this.wheelchairNeedsApi.validate(),
        this.isSeatReservationMadeApi.validate(),
        this.isOnlineCheckInCompletedApi.validate(),
        this._validateMeasurements()
      )

      if (this.accommodationSpecialRequirementsApi.getSelectedValues().includes('other')) {
        validations = validations.concat(this.otherAccommodationSpecialRequirementsApi.validate())
      }

      if (this.isFoldedWheelchairApi.getSelectedValues()[0] === 'yes') {
        validations = validations.concat(
          this.wheelchairFoldedHeightApi.validate(),
          this.wheelchairFoldedWidthApi.validate(),
          this.wheelchairFoldedLengthApi.validate()
        )
      }

      if (this.hasTransfer) {
        validations = validations.concat(
          this.isParticipantWheelchairTransferRequiredApi.validate()
        )
      }

      if (medicalTypeValue === medicalTypeOptions.electricWheelchair) {
        validations = validations.concat(
          this.isDryOrWetBatteryApi.validate(),
          this.brandOfBatteryApi.validate(),
          this.typeOfBatteryApi.validate(),
          this.voltageOfBatteryApi.validate(),
          this.ampereHoursOfBatteryApi.validate(),
          this.wattHoursOfBatteryApi.validate(),
          this.weightOfBatteryApi.validate(),
          this.canBatteryBeDisconnectedApi.validate(),
          this.isWheelchairTiltPossibleApi.validate()
        )
      }

      return validations.every(validation => validation.isValid)
    }
  }

  _validatePetLuggage () {
    let validations = [
      this.animalTypeTextBoxApi.validate(),
      this.breedTextBoxApi.validate()
    ]

    if (this.transportType === transportTypeOptions.flight) {
      validations = validations.concat(
        this._validateMeasurements(),
        this.containerPetTypeApi.validate(),
        this.transportationAreaApi.validate()
      )
    }

    if (this.hasCarRental) {
      validations = validations.concat(
        this.petHeightTextBoxApi.validate(),
        this.petLengthTextBoxApi.validate(),
        this.petWeightTextBoxApi.validate()
      )
    }
    return validations.every(validation => validation.isValid)
  }

  _validateBikeLuggage () {
    return this._validateMeasurements().concat(this.packageTypeDropdownApi.validate()).every(validation => validation.isValid)
  }

  _validateGeneralLuggage () {
    return this._validateMeasurements().every(validation => validation.isValid)
  }

  _validateMeasurements () {
    return [
      this.heightTextBoxApi.validate(),
      this.weightTextBoxApi.validate(),
      this.lengthTextBoxApi.validate(),
      this.widthTextBoxApi.validate()
    ]
  }

  _validateCheckbox (input, checkbox) {
    const isChecked = input.checked
    checkbox.classList.toggle('has-error', !isChecked)
    return isChecked
  }

  _enableErrors () {
    const components = [
      this.luggageTypeDropdownApi,
      this.participantDropdownApi,
      this.heightTextBoxApi,
      this.widthTextBoxApi,
      this.lengthTextBoxApi,
      this.weightTextBoxApi,
      this.packageTypeDropdownApi,
      this.animalTypeTextBoxApi,
      this.breedTextBoxApi,
      this.transportationAreaApi,
      this.containerPetTypeApi,
      this.petHeightTextBoxApi,
      this.petLengthTextBoxApi,
      this.petWeightTextBoxApi,
      this.isBringingMedicineApi,
      this.isNeedingExtraMedicineKilosApi,
      this.medicalTypeApi,
      this.medicalBagTypeApi,
      this.isFoldedWheelchairApi,
      this.wheelchairFoldedHeightApi,
      this.wheelchairFoldedWidthApi,
      this.wheelchairFoldedLengthApi,
      this.isParticipantWalkingApi,
      this.isParticipantClimbingStairsApi,
      this.isParticipantClimbingAirplaneStairsApi,
      this.wheelchairNeedsApi,
      this.isSeatReservationMadeApi,
      this.isOnlineCheckInCompletedApi,
      this.isParticipantWheelchairTransferRequiredApi,
      this.otherAccommodationSpecialRequirementsApi,
      this.isDryOrWetBatteryApi,
      this.isDryOrWetBatteryApi,
      this.brandOfBatteryApi,
      this.typeOfBatteryApi,
      this.voltageOfBatteryApi,
      this.ampereHoursOfBatteryApi,
      this.wattHoursOfBatteryApi,
      this.weightOfBatteryApi,
      this.canBatteryBeDisconnectedApi,
      this.isWheelchairTiltPossibleApi,
      this.isCpapHandLuggageCompliantApi,
      this.isMedicalEquipmentNeededOnBoardApi,
      this.isMedicalAirportAssistanceNeededApi
    ]
    components.forEach(component => {
      component.enableErrors()
    })
  }

  _resetForm () {
    this.readyToValidate = false
    this._resetField(this.luggageTypeDropdown, this.luggageTypeDropdownApi, 'dropdown')
    this._resetField(this.participantDropdown, this.participantDropdownApi, 'dropdown')
    this._resetField(this.heightTextBox, this.heightTextBoxApi, 'textbox')
    this._resetField(this.widthTextBox, this.widthTextBoxApi, 'textbox')
    this._resetField(this.lengthTextBox, this.lengthTextBoxApi, 'textbox')
    this._resetField(this.weightTextBox, this.weightTextBoxApi, 'textbox')
    this._resetField(this.packageTypeDropdown, this.packageTypeDropdownApi, 'dropdown')
    this._resetField(this.animalTypeTextBox, this.animalTypeTextBoxApi, 'textbox')
    this._resetField(this.breedTextBox, this.breedTextBoxApi, 'textbox')
    this._resetField(this.transportationArea, this.transportationAreaApi, 'dropdown')
    this._resetField(this.containerPetType, this.containerPetTypeApi, 'dropdown')
    this._resetField(this.petHeightTextBox, this.petHeightTextBoxApi, 'textbox')
    this._resetField(this.petLengthTextBox, this.petLengthTextBoxApi, 'textbox')
    this._resetField(this.petWeightTextBox, this.petWeightTextBoxApi, 'textbox')
    this._resetField(this.medicalType, this.medicalTypeApi, 'dropdown')
    this._resetField(this.isBringingMedicine, this.isBringingMedicineApi, 'choice-list')
    this._resetField(this.isNeedingExtraMedicineKilos, this.isNeedingExtraMedicineKilosApi, 'choice-list')
    this._resetField(this.medicalBagType, this.medicalBagTypeApi, 'textbox')
    this._resetField(this.isFoldedWheelchair, this.isFoldedWheelchairApi, 'choice-list')
    this._resetField(this.wheelchairFoldedHeight, this.wheelchairFoldedHeightApi, 'textbox')
    this._resetField(this.wheelchairFoldedWidth, this.wheelchairFoldedWidthApi, 'textbox')
    this._resetField(this.wheelchairFoldedLength, this.wheelchairFoldedLengthApi, 'textbox')
    this._resetField(this.isParticipantWalking, this.isParticipantWalkingApi, 'choice-list')
    this._resetField(this.isParticipantClimbingStairs, this.isParticipantClimbingStairsApi, 'choice-list')
    this._resetField(this.isParticipantClimbingAirplaneStairs, this.isParticipantClimbingAirplaneStairsApi, 'choice-list')
    this._resetField(this.wheelchairNeeds, this.wheelchairNeedsApi, 'choice-list')
    this._resetField(this.isSeatReservationMade, this.isSeatReservationMadeApi, 'choice-list')
    this._resetField(this.isOnlineCheckInCompleted, this.isOnlineCheckInCompletedApi, 'choice-list')
    this._resetField(this.accommodationSpecialRequirements, this.accommodationSpecialRequirementsApi, 'dropdown-multiple')
    this._resetField(this.isParticipantWheelchairTransferRequired, this.isParticipantWheelchairTransferRequiredApi, 'choice-list')
    this._resetField(this.otherAccommodationSpecialRequirements, this.otherAccommodationSpecialRequirementsApi, 'textbox')
    this._resetField(this.isDryOrWetBattery, this.isDryOrWetBatteryApi, 'choice-list')
    this._resetField(this.brandOfBattery, this.brandOfBatteryApi, 'textbox')
    this._resetField(this.typeOfBattery, this.typeOfBatteryApi, 'textbox')
    this._resetField(this.voltageOfBattery, this.voltageOfBatteryApi, 'textbox')
    this._resetField(this.ampereHoursOfBattery, this.ampereHoursOfBatteryApi, 'textbox')
    this._resetField(this.wattHoursOfBattery, this.wattHoursOfBatteryApi, 'textbox')
    this._resetField(this.weightOfBattery, this.weightOfBatteryApi, 'textbox')
    this._resetField(this.canBatteryBeDisconnected, this.canBatteryBeDisconnectedApi, 'choice-list')
    this._resetField(this.isWheelchairTiltPossible, this.isWheelchairTiltPossibleApi, 'choice-list')
    this._resetField(this.isCpapHandLuggageCompliant, this.isCpapHandLuggageCompliantApi, 'choice-list')
    this._resetField(this.isMedicalEquipmentNeededOnBoard, this.isMedicalEquipmentNeededOnBoardApi, 'choice-list')
    this._resetField(this.isMedicalAirportAssistanceNeeded, this.isMedicalAirportAssistanceNeededApi, 'choice-list')
    this.termsAndConditionsInput.checked = false
    this.medicalCertificateInput.checked = false
    this.termsAndConditionsCheckbox.classList.remove('has-error')
    this.medicalCertificateCheckbox.classList.remove('has-error')
  }

  _resetField (field, fieldApi, type) {
    const removeErrorClass = () => field.classList.remove('has-error')
    if (type === 'choice-list') {
      this._resetOptions(fieldApi)
      field.querySelectorAll('.c-choice-list__option').forEach(removeErrorClass)
    } else if (type === 'dropdown-multiple') {
      this._resetOptions(fieldApi)
      removeErrorClass()
    } else {
      fieldApi.setProp('value', '')
      removeErrorClass()
    }
    const fieldMessageElement = field.querySelector(`.c-${type}__messages`)
    if (fieldMessageElement) fieldMessageElement.innerHTML = ''
  }

  _resetOptions (api) {
    const options = api.getProp('options')
    if (!options) return
    const resetOptions = options.map(option => ({ ...option, checked: false }))
    api.setProp('options', resetOptions, { forceUpdate: true })
  }

  _openOnLoad () {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has(urlParamName)) {
      this.modalApi.open()
    }
  }
}

registerWidget(AccountSpecialLuggage, widgetApi)
