import { registerWidget } from '../../../js/core/widget/widget-directory'
import { elementFromString, flush } from '../../../js/document/html-helper'
import BookingOptionalAncillariesPopupDataMapper from './data-mapper'
import {
  widgetApi,
  widgetQueries,
  configOptions,
  config,
  api
} from './config'
import { BookingOptionalAncillariesPopupBodyTemplate } from './w-booking-optional-ancillaries-popup-body.template'
import registeredEvents from '../../../js/helpers/registered-events'
import { bookingOptionalAncillariesPopupEvents } from '../../../js/document/event-types'
import BookingAwaitablePopup from '../booking-awaitable-popup/main'

export default class BookingOptionalAncillariesPopup extends BookingAwaitablePopup {
  /**
  * Creates a new my widget.
  *
  * @constructor
  * @param {HTMLElement} element - The HTML element.
  * @param {BookingOptionalAncillariesPopup} [options={}] - Options object
  */
  constructor (element) {
    super(element)
    if (!element) { return }
    this.element = element
    this.skipBeforeNextStepValidation = false

    this.dataMapper = new BookingOptionalAncillariesPopupDataMapper()
    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.configurations = this._readOptionsFromConfigurations(filterConfigurationElement)

    this._initHtmlElements()

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.element.attributes[widgetQueries.trackAttr].value }
    })
  }

  /**
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  async _updateWidgetData () {
    this.widgetData = null
    this.optionalServicesNotSelected = this.data.optionalServicesNotSelected || null

    if (this.optionalServicesNotSelected) {
      this.widgetData = this.dataMapper.mapServices(this.optionalServicesNotSelected, this.configurations)
    }

    if (this.widgetData) {
      this.showComponent()
      this._init(this.widgetData)
    } else {
      this.hideComponent()
      this.skipBeforeNextStepValidation = true
    }
  }

  /**
   * Function to be executed before moving to the next step.
   * It will open the pop up if present, and will not contiue to the next step
   * till the customer doesn't accept the popup window
   */
  async beforeNextStep () {
    if (this.skipBeforeNextStepValidation) { return true }

    return super.beforeNextStep()
  }

  _initHtmlElements () {
    if (!this.modalBody) { return }
    const servicesBody = elementFromString('<div data-w-booking-optional-ancillaries-popup__services-body></div>')
    this.modalBody.insertAdjacentElement('afterend', servicesBody)

    this.servicesBodyElement = this.element.querySelector(widgetQueries.servicesBodyElement)
  }

  _init (data) {
    if (data.optionalServices && data.optionalServices.length > 0 && this.modalBody) {
      const renderedHtml = BookingOptionalAncillariesPopupBodyTemplate(data)
      const newContent = elementFromString(renderedHtml)
      if (this.servicesBodyElement) {
        flush(this.servicesBodyElement)
        this.servicesBodyElement.appendChild(newContent)
      }
    }
  }

  _attachEvents () {
    super._attachEvents()
    this.closeButton && this.closeButton[api.btn].events.on('clickButton', this._closeButtonClicked, this)
    this.acceptButton && this.acceptButton[api.btn].events.on('clickButton', this._acceptButtonClicked, this)

    if (this.modalApi) {
      this.modalApi.events.on('closed', () => {
        this.skipBeforeNextStepValidation = true
      })
    }
  }

  _acceptButtonClicked () {
    this.modalApi && this.modalApi.close()
    this.events.emit(bookingOptionalAncillariesPopupEvents.ACCEPT_BUTTON_CLICKED)
  }

  _closeButtonClicked () {
    this._scrollWindowToFirstService()
    this.skipBeforeNextStepValidation = true
  }

  _scrollWindowToFirstService () {
    this.events.emit(bookingOptionalAncillariesPopupEvents.CLOSE_BUTTON_CLICKED)
    if (this.widgetData && this.widgetData.optionalServices && this.widgetData.optionalServices.length > 0) {
      const serviceElement = document.querySelector(`[data-component-id="${this.widgetData.optionalServices[0].componentId}"]`)
      serviceElement && serviceElement.scrollIntoView(config.scroll)
    }
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)

      return {
        optionalServicesConfigurations: configuration[configOptions.optionalServicesConfigurations]
      }
    }
  }
}

registerWidget(BookingOptionalAncillariesPopup, widgetApi)
