import { registerWidget } from '../../../js/core/widget/widget-directory'
import { language } from '../../../js/user/locale-settings'
import { register } from '../../../js/document/namespace'
import Component from '../../../js/core/component/component'
import { observerAPI, documentObserver } from '../../../js/document/intersector'

const WIDGET_LOCALES = register(`window.sundio.i18n.${language}.ddGrid`)

const EventEmitter = require('eventemitter3')
const WIDGET_API = 'w-dd-grid'
const WIDGET_QUERIES = {
  cellsAtRow: (i) => `.${WIDGET_API}__cell:nth-child(${i + 1})`,
  panorama: `.${WIDGET_API}__panorama`,
  priceCells: `button.${WIDGET_API}__cell:not(:empty)`,
  prevElements: `.${WIDGET_API}__navigation--left`,
  nextElements: `.${WIDGET_API}__navigation--right`,
  addNextMonthElement: `.${WIDGET_API}__navigation-add-next-month-button`,
  addNextMonthColumn: `.${WIDGET_API}__navigation-add-next-month-column`,
  headerCell: `.${WIDGET_API}__column .${WIDGET_API}__cell--header`,
  leftArea: `.${WIDGET_API}__left-area`,
  rightArea: `.${WIDGET_API}__right-area`
}

const defaults = {
  prevMonthEnabled: true,
  nextMonthEnabled: true,
  origin: 'start'
}

export default class DDGrid {
  /**
   * Creates a new DDGrid
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element.
   * @param {options} [options={}] - Options object
   *
   */
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...defaults,
      ...options
    }

    this.events = new EventEmitter()

    this.elements = this.runElementQueries()
    this.hoveredCell = undefined

    this.elements.panorama.addEventListener('mousemove', this.handlePanoramaMouseMove.bind(this))
    this.elements.panorama.addEventListener('mouseleave', this.clearHoveredCell.bind(this))
    this.elements.panorama.addEventListener('click', this.handlePanoramaClick.bind(this))

    // Expose widget public API
    this.element[WIDGET_API] = {
      element: this.element,
      options: this.options,
      events: this.events
    }

    Component.initDocumentComponentsFromAPI(this.element)
    this.bindApisElements()

    this.bindNavigationEvents()
    this.updateNavigationArrows(!this.options.prevMonthEnabled, !this.options.nextMonthEnabled)
    this.refreshAddMonthElements({ addNextMonthEnabled: this.options.addNextMonthEnabled })
  }

  runElementQueries () {
    return {
      panorama: this.element.querySelector(WIDGET_QUERIES.panorama),
      priceCells: [...this.element.querySelectorAll(WIDGET_QUERIES.priceCells)],
      prevElements: this.element.querySelector(WIDGET_QUERIES.prevElements),
      nextElements: this.element.querySelector(WIDGET_QUERIES.nextElements),
      addNextMonthElement: this.element.querySelector(WIDGET_QUERIES.addNextMonthElement),
      addNextMonthColumn: this.element.querySelector(WIDGET_QUERIES.addNextMonthColumn),
      dateHeaderElements: [...this.element.querySelectorAll(WIDGET_QUERIES.headerCell)],
      leftArea: this.element.querySelector(WIDGET_QUERIES.leftArea),
      rightArea: this.element.querySelector(WIDGET_QUERIES.rightArea)
    }
  }

  handlePanoramaMouseMove (e) {
    // Omit action if we are over hoveredCell
    if (e.target === this.hoveredCell) return
    if (e.target.disabled) {
      this.clearHoveredCell()
      return
    }
    // Select the new cell or clean the previous one
    // TODO The parent.parent stuff is to prevent the event from being missed if the page has been translated with Google Translate
    if (this.elements.priceCells.includes(e.target) || this.elements.priceCells.includes(e.target.parentElement.parentElement)) {
      const target = this.elements.priceCells.includes(e.target) ? e.target : e.target.parentElement.parentElement
      this.clearHoveredCell()
      this.applyHoverOnCell(target)
    } else {
      this.clearHoveredCell()
    }
  }

  handlePanoramaClick (e) {
    if (e.target === this.selectedCell) return
    // TODO The parent.parent stuff is to prevent the event from being missed if the page has been translated with Google Translate
    if (e.target !== this.hoveredCell && e.target.parentElement.parentElement !== this.hoveredCell) return
    const target = e.target === this.hoveredCell ? e.target : e.target.parentElement.parentElement
    if (target.disabled) return
    this.clearHoveredCell()
    this.clearSelectedCell()
    this.applySelectedOnCell(target)
  }

  getIndexesByCell (cell) {
    return {
      columnIndex: Object.values(this.elements.panorama.children).indexOf(cell.parentElement),
      rowIndex: Object.values(cell.parentElement.children).indexOf(cell)
    }
  }

  getColumnAndRowElementsByIndexes (cell, { columnIndex, rowIndex }) {
    return {
      columnElements: Object.values(cell.parentElement.children).slice(0, rowIndex),
      rowElements: [...this.element.querySelectorAll(WIDGET_QUERIES.cellsAtRow(rowIndex))].slice(0, columnIndex + 1)
    }
  }

  applyHoverOnCell (cell) {
    this.hoveredCell = cell
    const { columnElements, rowElements } = this.getColumnAndRowElementsByIndexes(
      cell,
      this.getIndexesByCell(cell)
    )
    this.hoveredColumnElements = columnElements
    this.hoveredRowElements = rowElements
    this.toggleHoveredStates(true)
  }

  applySelectedOnCell (selectedCell) {
    this.selectedCell = selectedCell
    const { columnElements, rowElements } = this.getColumnAndRowElementsByIndexes(
      selectedCell,
      this.getIndexesByCell(selectedCell)
    )
    this.selectedColumnElements = columnElements
    this.selectedRowElements = rowElements
    this.toggleSelectedStates(true)

    this.events.emit('priceSelected', {
      duration: selectedCell.dataset.duration,
      departure: selectedCell.dataset.departureDate,
      price: selectedCell.dataset.price,
      transportType: selectedCell.dataset.transportType,
      isFlightOnly: selectedCell.dataset.isFlightOnly?.toLowerCase() === 'true',
      mealplan: selectedCell.dataset.mealplan,
      bestValueScore: selectedCell.dataset.bestValueScore,
      selectedPriceCell: selectedCell
    })
  }

  clearHoveredCell () {
    if (!this.hoveredCell) return
    this.toggleHoveredStates(false)
    this.hoveredCell = undefined
    this.hoveredColumnElements = undefined
    this.hoveredRowElements = undefined
  }

  clearSelectedCell () {
    if (!this.selectedCell) return
    this.toggleSelectedStates(false)
    this.selectedCell = undefined
    this.selectedColumnElements = undefined
    this.selectedRowElements = undefined
  }

  toggleHoveredStates (state = true) {
    this.hoveredCell.classList.toggle('is-hovered', state)
    this.hoveredColumnElements.forEach(e => e.classList.toggle('on-hovered-column', state))
    this.hoveredRowElements.forEach(e => e.classList.toggle('on-hovered-row', state))
  }

  toggleSelectedStates (state = true) {
    this.selectedCell.classList.toggle('is-selected', state)
    this.selectedColumnElements.forEach(e => e.classList.toggle('on-selected-column', state))
    this.selectedRowElements.forEach(e => e.classList.toggle('on-selected-row', state))
  }

  updateNavigationArrows (disableLeft, disableRight) {
    const observer = documentObserver()

    if (disableLeft) {
      const firstDateHeaderElement = this.elements.dateHeaderElements[0]
      observer.observe(firstDateHeaderElement)
      firstDateHeaderElement[observerAPI].events.on('enter', () => {
        this.elements.prevElements.disabled = true
      })
      firstDateHeaderElement[observerAPI].events.on('leave', () => {
        this.elements.prevElements.disabled = false
      })
    }

    if (disableRight) {
      const lastDateHeaderElement = this.elements.dateHeaderElements[this.elements.dateHeaderElements.length - 1]
      observer.observe(lastDateHeaderElement)
      lastDateHeaderElement[observerAPI].events.on('enter', () => {
        this.elements.nextElements.disabled = true
      })
      lastDateHeaderElement[observerAPI].events.on('leave', () => {
        this.elements.nextElements.disabled = false
      })
    }
  }

  bindApisElements () {
    if (this.elements.addNextMonthElement) {
      this.addNextMonthButton = this.elements.addNextMonthElement['c-btn']
    }
  }

  bindNavigationEvents () {
    this.elements.prevElements && this.elements.prevElements.addEventListener('click', (ev) => this.prev(ev, { silent: false }))
    this.elements.nextElements && this.elements.nextElements.addEventListener('click', (ev) => this.next(ev, { silent: false }))
    this.elements.addNextMonthElement && this.elements.addNextMonthElement.addEventListener('click', (ev) => this.emitAddMonth({ direction: 'next', silent: false }))
  }

  refreshScrollPosition () {
    if (this.options.origin === 'end') {
      this.elements.panorama.style.scrollBehavior = 'auto'
      this.move(1, 'last', { silent: true })
      this.elements.panorama.style.scrollBehavior = null
    }
  }

  refreshScrollSpecificPosition (scrollTo) {
    if (scrollTo !== undefined) {
      this.elements.panorama.style.scrollBehavior = 'auto'
      this.elements.panorama.scrollLeft = scrollTo
      this.elements.panorama.style.scrollBehavior = null
    }
  }

  getScrollPosition () {
    return this.elements.panorama.scrollLeft
  }

  prev (ev, options = {}) {
    ev.preventDefault()
    ev.target.disabled = true
    this.move(-1, null, options)
    ev.target.disabled = false
  }

  next (ev, options = {}) {
    ev.preventDefault()
    ev.target.disabled = true
    this.move(1, null, options)
    ev.target.disabled = false
  }

  goTo (date, options = {}) {
    return this.move(1, date, options)
  }

  move (direction = 1, goTo, options = {}) {
    direction = (direction < 0) ? -1 : 1

    const visiblePanoramaWidth = this.elements.panorama.offsetWidth
    const columnWidth = this.elements.dateHeaderElements[0].offsetWidth
    const visibleElementsAmount = parseInt(visiblePanoramaWidth / columnWidth)
    const totalPanoramaWidth = this.elements.panorama.scrollWidth
    const scrollLeftMoved = this.elements.panorama.scrollLeft
    const scrollToMoveRight = totalPanoramaWidth - scrollLeftMoved - visiblePanoramaWidth
    const numElementsScrolledLeft = parseInt(scrollLeftMoved / columnWidth)

    if (goTo === 'last') {
      this.scrollToRight(scrollToMoveRight, scrollToMoveRight, options)
    } else if (goTo) {
      const columnPosition = this.elements.dateHeaderElements.findIndex(item => item.dateTime === goTo)
      if (columnPosition > (numElementsScrolledLeft + visibleElementsAmount)) {
        const numberOfColumnsToScroll = columnPosition - parseInt(visibleElementsAmount / 2)
        const scroll = direction * ((numberOfColumnsToScroll * columnWidth))
        this.scrollToRight(scrollToMoveRight, scroll, options)
      } else {
        const numberOfColumnsToScroll = numElementsScrolledLeft - parseInt(visibleElementsAmount / 2) - 1
        const scroll = -1 * ((numberOfColumnsToScroll * columnWidth))
        this.scrollToLeft(scrollLeftMoved, scroll, options)
      }
    } else {
      if (direction < 0) {
        const missingMovedLeft = scrollLeftMoved > (numElementsScrolledLeft * columnWidth) ? columnWidth - (scrollLeftMoved - (numElementsScrolledLeft * columnWidth)) : 0
        const scroll = direction * ((visibleElementsAmount * columnWidth) - (missingMovedLeft))
        this.scrollToLeft(scrollLeftMoved, scroll, options)
      } else {
        const extraScrollNotAllCell = scrollLeftMoved - (numElementsScrolledLeft * columnWidth)
        const scroll = direction * ((visibleElementsAmount * columnWidth) - extraScrollNotAllCell)
        this.scrollToRight(scrollToMoveRight, scroll, options)
      }
    }

    if (!options.silent) {
      this.events.emit('move', {
        direction,
        instance: this
      })
    }

    return this
  }

  scrollToLeft (scrollLeftMoved, scroll, options) {
    if (scrollLeftMoved > 0) {
      this.elements.panorama.scrollLeft += scroll
    } else if (scrollLeftMoved <= 0 && this.options.prevMonthEnabled) {
      if (!options.silent) {
        this.events.emit('prevEdge', {
          direction: 'prev',
          instance: this
        })
      }
      this.clearSelectedCell()
      return this
    }
  }

  scrollToRight (scrollToMoveRight, scroll, options) {
    if (scrollToMoveRight > 0) {
      this.elements.panorama.scrollLeft += scroll
    } else if (scrollToMoveRight <= 0 && this.options.nextMonthEnabled) {
      if (!options.silent) {
        this.events.emit('nextEdge', {
          direction: 'next',
          instance: this
        })
      }
      this.clearSelectedCell()
      return this
    }
  }

  emitAddMonth (options = {}) {
    if (!options.silent) {
      this.events.emit('addMonth', {
        direction: options.direction,
        instance: this
      })
    }
    return this
  }

  refreshAddMonthElements (options = {}) {
    options = {
      addNextMonthEnabled: true,
      ...options
    }
    if (this.addNextMonthButton) {
      this.addNextMonthButton.setProp('disabled', !options.addNextMonthEnabled)
    }
  }

  refreshLoadingAddMonthElement (isLoading) {
    if (this.addNextMonthButton) {
      this.addNextMonthButton.setProp('loading', isLoading)
    }
  }

  static getLocales (id = null) {
    const customLocaleElement = id !== null ? document.querySelector(`[data-type="i18n"][data-uid="${id}"]`) : null
    if (!customLocaleElement) return WIDGET_LOCALES

    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return {
      ...WIDGET_LOCALES,
      ...customLocaleData
    }
  }
}

registerWidget(DDGrid, WIDGET_API)
