// Import helpers, polyfills & utils
require('../../../js/polyfills/main')
require('../../objects/swipe/main')
require('../../../js/locales/_all')
require('../../../js/document/anchors')

// Import components
require('../../components/autocomplete/main')
require('../../components/absolute-dialog/main')
require('../../components/backdrop/main')
require('../../components/btn/main')
require('../../components/button-group/main')
require('../../components/calendar-field/main')
require('../../components/calendar/data-js-api')
require('../../components/card/main')
require('../../components/choice-list/main')
require('../../components/collapse/main')
require('../../components/date-selector/main')
require('../../components/dropdown/main')
require('../../components/dropdown/dropdown-multiple/main')
require('../../components/favorite/button')
require('../../components/favorite/cleaner')
require('../../components/favorite/collector')
require('../../components/floating-box/main')
require('../../components/img/data-js-api')
require('../../components/img-box/main')
require('../../components/modal/main')
require('../../components/modal-v2/main')
require('../../components/nav/main')
require('../../components/number-score/main')
require('../../components/number-stepper/main')
require('../../components/pagination/main')
require('../../components/participants-selector/main')
require('../../components/phone-input/main')
require('../../components/photo-gallery/main')
require('../../components/price-table/main')
require('../../components/progress-bar/main')
require('../../components/range/main')
require('../../components/side-drawer/main')
require('../../components/slider/main')
require('../../components/tabs/main')
require('../../components/textarea/main')
require('../../components/textbox/main')
require('../../components/tooltip/main')
require('../../components/form/data-js-api')
require('../../components/form/main')
require('../../components/actionable-list/main')
require('../../components/map-interactive/main')
require('../../components/video/main')

require('../../../js/core/component/component-api')

// Import widgets
require('../../widgets/data-layer/main')
require('../../widgets/static-content-data/main')
require('../../widgets/acco-structured-data/main')
require('../../widgets/acco-lister/main')
require('../../widgets/booked-service-basket/main')
require('../../widgets/booked-service-edit/main')
require('../../widgets/booked-service-error/main')
require('../../widgets/booked-services-list/main')
require('../../widgets/booking-optional-ancillaries-popup/main')
require('../../widgets/booking-accommodation-summary/main')
require('../../widgets/booking-back-button/main')
require('../../widgets/booking-basket/main')
require('../../widgets/booking-bundle/main')
require('../../widgets/booking-cancellation-flow/main')
require('../../widgets/booking-car-rental/main')
require('../../widgets/booking-changed-notification/main')
require('../../widgets/booking-choice-list/main')
require('../../widgets/booking-gate/main')
require('../../widgets/booking-generic-summary/main')
require('../../widgets/booking-comparison-table/main')
require('../../widgets/booking-confirmation-summary/main')
require('../../widgets/booking-coupon-code/main')
require('../../widgets/booking-luggage/main')
require('../../widgets/booking-message/main')
require('../../widgets/booking-error/main')
require('../../widgets/booking-participant-summary/main')
require('../../widgets/booking-participants-badge/main')
require('../../widgets/booking-participants-form/main')
require('../../widgets/booking-price-overview/main')
require('../../widgets/booking-steps-navigation/main')
require('../../widgets/booking-steps-tabs/main')
require('../../widgets/booking-remarks/main')
require('../../widgets/booking-preferences-selector/main')
require('../../widgets/booking-transport-summary/main')
require('../../widgets/booking-terms-conditions/main')
require('../../widgets/booking-ecommerce-analytics/main')
require('../../widgets/booking-filterable-services/main')
require('../../widgets/booking-redeemer/main')
require('../../widgets/booking-button-open-page/main')
require('../../widgets/car-rental/main')
require('../../widgets/cookie-consent/main')
require('../../widgets/cookie-emergency-dialog/main')
require('../../widgets/cookie-modal/main')
require('../../widgets/customer-discounts/main')
require('../../widgets/dd-grid/main')
require('../../widgets/dropdown-redirect-group/main')
require('../../widgets/faq-feedback/main')
require('../../widgets/faq-top-questions/main')
require('../../widgets/flight-bus-selector/main')
require('../../widgets/language-modal/main')
require('../../widgets/live-search/main')
require('../../widgets/new-live-search/main')
require('../../widgets/login-form/main')
require('../../widgets/payment/main')
require('../../widgets/promoted-price/main')
require('../../widgets/quick-search/main')
require('../../widgets/rebooking-options/main')
require('../../widgets/reviews-list/data-js-api')
require('../../widgets/room-occupancy/main')
require('../../widgets/room-selector/main')
require('../../widgets/search/main')
require('../../widgets/selected-state/main')
require('../../widgets/subscribe-form/main')
require('../../widgets/dropdown-redirect/main')
require('../../widgets/dynamic-label/main')
require('../../widgets/ski-prices/data-js-api')
require('../../widgets/smart-app-banner/main')
require('../../widgets/room-dropdown/main')
require('../../widgets/room-list/main')
require('../../widgets/product-ads/main')
require('../../widgets/product-map/main')
require('../../widgets/personal-details-edit/main')
require('../../widgets/voucher/main')
require('../../widgets/voucher-redeemer/main')
require('../../widgets/refund/main')
require('../../widgets/voucher-check-balance/main')
require('../../widgets/booking-vouchers/main')
require('../../widgets/action-modal/main')
require('../../widgets/delete-account/main')
require('../../widgets/ce-login/main')
require('../../widgets/ce-modal/main')
require('../../widgets/ce-welcome-msg/main')
require('../../widgets/download-documents/main')
require('../../widgets/excursions/main')
require('../../widgets/transfer-summary/main')
require('../../widgets/verify-account/main')
require('../../widgets/preference-questionnaire/main')
require('../../widgets/welcome-user/main')
require('../../widgets/profile-data/main')
require('../../widgets/permission-settings/main')
require('../../widgets/booking-usps/main')
require('../../widgets/reviews-teaser/main')
require('../../widgets/main-menu/main')
require('../../widgets/account-menu/main')
require('../../widgets/recommended-acco-lister/main')
require('../../widgets/acco-nav/main')
require('../../widgets/login-status/main')
require('../../widgets/account-special-luggage/main')

// This booking-steps widget should be the last to be required
require('../../widgets/booking-steps/main')

require('../../../js/core/widget/widget-api')

// Autoload all components when the page is ready
const { autoload } = require('../../../js/document/load-queue')
autoload()
