import ApiFilters from './api-filters'
import ApiPriceTable from './api-price-table'
import FiltersCollection from '../../../js/data/filters/filters-collection'
import PriceTable from '../../components/price-table/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { getAbsoluteUrl, getSearchParamsFromUrl, getUrlFromString, buildUrlWithParams } from '../../../js/document/url'
import { fromCamelCase, toCamelCase } from '../../../js/helpers/string'
import { removeUndefinedKeys } from '../../../js/helpers/object'
import { debounce } from '../../../js/utils'
import { elementFromString, flush, moveChildrenFrom, isElementInViewport } from '../../../js/document/html-helper'
import FlightBusSelector from '../flight-bus-selector/main'
import SelectedState from '../selected-state/main'
import { BookingGateItemTemplate } from './w-booking-gate__item.template'
import { arrayifyObject, derrayifyObject } from '../../../js/helpers/arrayify-object'
import SelfDriveSelectorDataModel from '../flight-bus-selector/self-drive-selector-data-model'
import { fetchJsonData, fetchJsonDataAndStatusInfo } from '../../../js/helpers/json-fetch'
import { BookingGateNoResultsTemplate } from './w-booking-gate__no-results.template'
import { BookingGateErrorTemplate } from './w-booking-gate__error.template'
import Component from '../../../js/core/component/component'
import { dateToString, getMonthEdges } from '../../../js/helpers/dates'
import { documentObserver, observerAPI } from '../../../js/document/intersector'
import { smooth } from '../../../js/document/scroll'
import { GET_WHITELISTED_PARAMS, EXCLUDE_WHITELISTED_PARAMS } from '../../../js/helpers/white-listed-params'
import { GET_PASSTHROUGH_PARAMS } from '../../../js/helpers/pass-through-params'
import { BookingGateMessagesTemplate } from './w-booking-gate__messages.template'
import PriceTableType from './price-table-type'
import ApiPriceTableGroupedByDuration from './api-price-table-grouped-by-duration'
import DDGrid from '../dd-grid/main'
import { DDGridTemplate } from '../dd-grid/w-dd-grid.template'
import RoomSelector from '../room-selector/main'
import registeredEvents from '../../../js/helpers/registered-events'
import { bookingGateEvents, tabsEvents } from '../../../js/document/event-types'
import domEventsHelper from '../../../js/document/dom-events-helper'
import { language } from '../../../js/user/locale-settings'
import { register } from '../../../js/document/namespace'
import { FILTER_TYPES, TRANSPORT_TYPE_VALUES } from '../../../js/data/filters/config'

import {
  WIDGET_API,
  ELEMENT_QUERIES,
  CORRECT_FILTER_NAMES,
  KNOWN_FILTER_VIEWS,
  HIDDEN_CLASSNAME,
  NEXT_SEASON_ITEM_TEMPLATE
} from './config'

require('../promoted-price/main')
require('../room-selector/main')

const COMPONENT_LOCALES = register(`window.sundio.i18n.${language}.bookingGate`)

const EventEmitter = require('eventemitter3')

const FLIGHT_ONLY_DEFAULT_ROOM_CODE = '1XXX'

export default class BookingGate {
  /**
   * Creates a new BookingGate
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach BookingGate
   * @param {Object} options
   */
  constructor (element, options = {}) {
    this.element = element
    this.events = new EventEmitter()
    this.element[WIDGET_API] = this

    // Get initial data from Dom
    this.elements = this.runElementQueries()
    this.apis = this.getApisFromDom()
    this.extraParams = this.getExtraParamsFromDom()
    this.itemIds = this.getItemIdsFromDom()
    this.localesData = this.getLocalesDataFromDom()
    this.locales = {
      ...COMPONENT_LOCALES
    }

    this.priceTableType = this.element.dataset.wBookingGate__priceTableType
    this.errorModalText = this.element.dataset.wBookingGate__errorModalText
    this.startBookingErrorModalText = this.element.dataset.wBookingGate__startBookingErrorModalText || 'It seems that it has not been possible to create your booking. Please, try it again later .'
    this.hasFlexibleAllocation = this.element.dataset.wBookingGate__hasFlexibleAllocation !== undefined
    this.showDurationInNights = this.element.dataset.wBookingGate__showDurationInNights !== undefined
    this.priceTableShowAddNextMonthButton = this.element.dataset.wBookingGate__priceTableShowAddNextMonthButton !== undefined
    this.mandatoryCostsCollapsed = this.element.dataset.wBookingGate__mandatoryCostsCollapsed !== undefined
    this.autoScrollEnabled = this.element.dataset.wBookingGate__autoScrollEnabled !== undefined
    this.participantsProfiles = []

    registeredEvents.registerWidgetEvents(WIDGET_API, this.events, {
      ...this.element.hasAttribute(ELEMENT_QUERIES.trackAttr) && { track: this.element.attributes[ELEMENT_QUERIES.trackAttr].value }
    })

    if (!PriceTableType.isValid(this.priceTableType)) {
      console.warn(`Unknown price table type: ${this.priceTableType}`)
      this.priceTableType = PriceTableType.DepartureDateAndRoomType
    }

    // Init either the normal price table or the price table grouped by durations.
    if (this.priceTableType === PriceTableType.DepartureDateAndRoomType) {
      this.priceTables = this.initPriceTables()
      this.apiPriceTable = new ApiPriceTable({ url: this.apis['price-table'] })
    } else {
      this.priceTables = []
      this.apiPriceTableGroupedByDuration = new ApiPriceTableGroupedByDuration({ url: this.apis['price-table'] })
    }

    this.filtersCollection = this.initFiltersCollection()
    this.promotedPrice = this.initPromotedPrice()
    this.apiFilters = new ApiFilters({ url: this.apis.filters })

    // Init and fill QueryParams
    this.whiteListedParams = GET_WHITELISTED_PARAMS(getSearchParamsFromUrl(document.location, { useUndefinedValue: true }))
    this.passThroughParams = GET_PASSTHROUGH_PARAMS(getSearchParamsFromUrl(document.location))
    this.queryParams = {
      ...this.extraParams,
      ids: this.itemIds
    }
    this.selectedValues = {
      ...this.getParticipantsDataFromComponent(),
      ...this.getSelectedValuesFromURL()
    }
    this._sanitizeTransportFilters()
    this.allocatedRooms = {}
    this._recalculateShouldSkipDatesForPromotedPrice()

    // If a focusPriceTable param is found on URL, will scroll and focus on the priceTable
    if (this.selectedValues.focusPriceTable) {
      this.focusCurrentTab()
      delete this.selectedValues.focusPriceTable
    }

    // Bind filters change to call updateApp with little delay to avoid event looping
    // NOTE: updateFilters should only happens when participantsChanged
    this.debouncedUpdateApp = debounce(this.updateApp, 150)
    this.events.on('filtersChange', (args) => {
      this.debouncedUpdateApp({ updateFilters: args.participantsChanged })
    })

    // whenToResolve can be load, intersect, or missing (same as load)
    const whenToResolve = this.element.dataset.wBookingGate__resolve || 'load'
    // Make the first request and update
    switch (whenToResolve) {
      case 'intersect': {
        this._addObserver((observer) => {
          this.updateApp({ updateFilters: true })
          observer.unobserve(this.element)
          // Re-attach observer
          this._addObserver(() => this.emitPriceTableViewed())
        })
        break
      }
      case 'load':
      default:
        this.updateApp({ updateFilters: true })
        this._addObserver(() => this.emitPriceTableViewed())
        break
    }
  }

  /*
  * -----------------------------------------------------
  * INITIALIZATION RELATED METHODS
  * -----------------------------------------------------
  */

  /**
   * Runs element queries
   *
   * @returns {Object}
   */
  runElementQueries () {
    return {
      filtersWrapper: this.element.querySelector(ELEMENT_QUERIES.filtersElement),
      filters: this.element.querySelectorAll(ELEMENT_QUERIES.filterElements(this.element.id)),
      filtersExceptParticipantsElements: this.element.querySelectorAll(ELEMENT_QUERIES.filtersExceptParticipantsElements),
      priceTableMessages: this.element.querySelector(ELEMENT_QUERIES.priceTableMessages),
      priceTableContainer: this.element.querySelector(ELEMENT_QUERIES.priceTableContainer),
      noResults: this.element.querySelector(ELEMENT_QUERIES.noResultsElement),
      errorSection: this.element.querySelector(ELEMENT_QUERIES.errorElement),
      promotedPrice: document.querySelector(ELEMENT_QUERIES.promotedPriceElement),
      errorModal: this.element.querySelector(ELEMENT_QUERIES.errorModal),
      errorModalButton: this.element.querySelector(ELEMENT_QUERIES.errorModalButton),
      outOfSeasonModal: this.element.querySelector(ELEMENT_QUERIES.outOfSeasonModal),
      outOfSeasonModalButton: this.element.querySelector(ELEMENT_QUERIES.outOfSeasonModalButton),
      startBookingErrorModal: this.element.querySelector(ELEMENT_QUERIES.startBookingErrorModal),
      startBookingErrorModalButton: this.element.querySelector(ELEMENT_QUERIES.startBookingErrorModalButton),
      openJawValidationModal: this.element.querySelector(ELEMENT_QUERIES.openJawValidationModal),
      openJawValidationModalButton: this.element.querySelector(ELEMENT_QUERIES.openJawValidationModalButton),
      openJawValidationModalCancelButton: this.element.querySelector(ELEMENT_QUERIES.openJawValidationModalCancelButton)
    }
  }

  /**
   * Returns the filtersData from URL params, if there's any
   * - Get the relevant params from the URL
   * - Derrayify data to a regular JS Object
   * - Addresses correct filter names problem, if needed
   *
   * @returns {Object}
   */
  getSelectedValuesFromURL () {
    if (!document.location.search) return {}
    const relevantUrlParams = EXCLUDE_WHITELISTED_PARAMS(
      removeUndefinedKeys(getSearchParamsFromUrl(document.location, { useUndefinedValue: true }))
    )

    if (!relevantUrlParams || Object.keys(relevantUrlParams).length === 0) return {}
    const urlParams = derrayifyObject(relevantUrlParams)

    // FIXME: ⚠ Build a departureDate with day & months params (Backwards compatibility) ⚠
    // Consider to deprecate this when DS is fully rolled out, and can be handled by DepartureDate
    if (
      Object.prototype.hasOwnProperty.call(urlParams, 'day') &&
      Object.prototype.hasOwnProperty.call(urlParams, FILTER_TYPES.MONTH) &&
      !Object.prototype.hasOwnProperty.call(urlParams, FILTER_TYPES.DEPARTURE_DATE)
    ) {
      urlParams.DepartureDate = urlParams.Month.substring(0, 8) + urlParams.day
    }

    // Return the final corrected object
    return Object.keys(urlParams).reduce((obj, key) => {
      obj[CORRECT_FILTER_NAMES[key] || key] = urlParams[key]
      return obj
    }, {})
  }

  /**
   * Gets extra params through hidden inputs on DOM
   *
   * @returns {Object}
   */
  getExtraParamsFromDom () {
    const extraParamsElement = this.element.querySelector(ELEMENT_QUERIES.extraParams)
    if (!extraParamsElement) return {}
    return [...extraParamsElement.querySelectorAll('input[type="hidden"]')].reduce((obj, el) => {
      obj[el.name] = el.value
      return obj
    }, {})
  }

  /**
   * Gets APIs names and URLs from a datalist element on DOM
   * - APIs urls will be converted to absolute if they're relative
   *
   * @returns {Object}
   */
  getApisFromDom () {
    const apisElement = this.element.querySelector(ELEMENT_QUERIES.apiDataList)
    return apisElement
      ? [...apisElement.children].reduce((obj, e) => ({ ...obj, [e.dataset.name]: getAbsoluteUrl(e.dataset.url) }), {})
      : {}
  }

  /**
   * Gets IDs object from a datalist element on DOM
   * - IDs are the filter instances/facets Sitecore item IDs, one per visible filter
   *
   * @returns {String[]}
   */
  getItemIdsFromDom () {
    const itemIdsElement = this.element.querySelector(ELEMENT_QUERIES.idsDataList)
    return itemIdsElement
      ? [...itemIdsElement.children].map(e => e.dataset.id)
      : []
  }

  /**
   * Gets locales data from JSON on DOM
   *
   * @returns {Object}
   */
  getLocalesDataFromDom () {
    const localesDataElement = document.querySelector(ELEMENT_QUERIES.localesData(this.element.id))
    return localesDataElement
      ? (() => {
          try {
            return JSON.parse(localesDataElement.innerText)
          } catch (err) { return {} }
        })()
      : {}
  }

  /**
   * Gets participants data from component in element if any
   * Returns a multidimensional array with the participants (BirthDates) organized into rooms allocation,
   * eg. [ ['1979-01-01','1985-03-03'], ['2013-01-01','2015-03-03'] ]
   *
   * @returns {[DateString[]]|undefined} Matching participants for that room
   */
  getParticipantsDataFromComponent () {
    const participantsSelector = this.element.querySelector('[data-js-component="c-participants-selector"]')
    this.participantsSelectorAPI = participantsSelector && participantsSelector['c-participants-selector']
    return this.participantsSelectorAPI
      ? {
          Participants: participantsSelector['c-participants-selector'].getParticipantsData() || undefined,
          Allocation: participantsSelector['c-participants-selector'].getAllocation() || undefined
        }
      : {}
  }

  /**
   * Focus currentTab
   */
  focusCurrentTab () {
    try {
      const currentTab = this.element.closest('.c-tabs__content')
      const currentTabId = currentTab.id
      const currentTabComponent = currentTab.closest('[data-js-component="c-tabs"]')['c-tabs']
      currentTabComponent.setProp('currentTab', currentTabId)
      currentTabComponent.setCurrentHashHistory(currentTabId)

      return true
    } catch (e) { }

    return false
  }

  _isPriceTabOpen () {
    const currentTab = this.element.closest('.c-tabs__content')
    return currentTab && currentTab.classList.contains('is-open')
  }

  _recalculateShouldSkipDatesForPromotedPrice () {
    this.shouldSkipDatesForPromotedPrice = !this.selectedValues.DepartureDate && !this.selectedValues.Month
  }

  /*
  * -----------------------------------------------------
  * STATE RELATED METHODS
  * -----------------------------------------------------
  * */

  /**
   * Fetch new data and update widget with it
   *
   * - Disable UI
   * - Request new data
   * - Update filters UI
   * - Enable UI
   *
   * @param {Object} options - Update options
   * @param {Boolean} [options.updateFilters=true] - If false, will not update filters, but will do for PT & PP
   * @param {Boolean} [options.updateUrl=true] - If false, will not update URL
   * @param {Boolean} [options.updatePromotedPrice=true] - If false, will not update PromotedPrice
   *
   * @returns {Promise}
   */
  async updateApp (options = {}) {
    options = {
      updateFilters: true,
      updateUrl: true,
      updatePromotedPrice: true,
      ...options
    }
    // Disable UI
    this.setEnabledState(false)
    this.hideError()
    if (this.promotedPrice && options.updatePromotedPrice) {
      this.promotedPrice.close()
    }

    const departureDate = this.selectedValues.DepartureDate || undefined
    let hasChangedSelectedValues = false

    if (options.updateFilters) {
      const params = removeUndefinedKeys({
        ...this.queryParams,
        ...this.selectedValues,
        ...this.passThroughParams
      })
      const filtersData = await this.apiFilters.fetch(params)

      // Handle response/communication errors
      // - Warn errors on console for debugging
      // - Flush price tables
      // - Lead To Unrecoverable Error
      // - Reject updateApp resolution
      if (filtersData.errors && filtersData.errors.length) {
        filtersData.errors.map(error => console.warn(error))
        this.priceTables = this.initPriceTables()
        if (this.promotedPrice) {
          this.promotedPrice.showFallback()
        }
        this.showError(this.localesData.filters?.errorMessage || this.locales.filtersError)
        this.events.emit(bookingGateEvents.BOOKING_GATE_ERRORS_THROWN, {
          origin: 'FetchPriceTableFilters',
          errors: filtersData.errors
        })
        this.setEnabledState(true)
        this.setEnabledStateOnFiltersExceptParticipants(false)
      }

      // Update filters with fresh data
      this.filtersCollection.reset(filtersData.filters, { silent: true })
      this.initOrUpdateFilterViews()
      this.selectedValues = this.filtersCollection.getFiltersSelectedValuesObject()
      this._sanitizeTransportFilters()
      hasChangedSelectedValues = true

      if (filtersData.errors && filtersData.errors.length) {
        return
      }
    }

    // Ensure received values are reflected to URL
    if (options.updateUrl) this.updateBrowserUrl()

    // Ensure Departure Airport Filter is shown/hidden according the transport type.
    this.toggleDepartureAirportVisibility()
    this.toggleArrivalAirportVisibility()
    this.toggleRoomTypeFilterVisibility()

    // Inject out of season if it's available
    this.initOutOfSeason()

    // Refresh promotedPrice
    if (this.promotedPrice && options.updatePromotedPrice) {
      if (hasChangedSelectedValues) {
        this._recalculateShouldSkipDatesForPromotedPrice(this.selectedValues)
      }

      // Build a departureDate value/range if needed
      const DepartureDate = this.shouldSkipDatesForPromotedPrice
        ? undefined
        : departureDate || getMonthEdges(this.selectedValues.Month).map(d => dateToString(d))
      this.promotedPrice.refresh({
        ...this.selectedValues,
        Month: undefined,
        DepartureDate
      })
      this.shouldSkipDatesForPromotedPrice = false
    }

    return this.updatePriceTable({ departureDate })
      .then(() => {
        this.setEnabledState(true)
        this.events.emit('fetchAndUpdateFinished')
        Promise.resolve()
      })
      .finally(() => { this.setEnabledState(true) })
  }

  /**
   * Update Browser's url with all the params excepting the hidden ones
   * - Should happen after each update
   * - The main purpose is to enable copy/share the URL
   *
   * @returns {BookingGate} self instance
   */
  updateBrowserUrl () {
    window.history.replaceState(
      '',
      '',
      buildUrlWithParams(
        document.URL.split('?')[0],
        arrayifyObject({
          ...removeUndefinedKeys(this.selectedValues),
          ...this.whiteListedParams,
          ...this.passThroughParams
        })
      ))
    return this
  }

  /**
   * Enable or Disable UI
   * - Filters element (wrapper)
   * - Main Body element (results, loading)
   *
   * @param {Boolean} enabled - The enable state
   *
   * @returns {BookingGate} self instance
   */
  setEnabledState (enabled = true) {
    this.element.classList.toggle('is-loading', !enabled)
    this.elements.filtersWrapper.classList.toggle('is-disabled', !enabled)
    this.setEnabledStateOnFiltersExceptParticipants(enabled)
    return this
  }

  setEnabledStateOnFiltersExceptParticipants (enabled = true) {
    this.elements.filtersExceptParticipantsElements.forEach(filterFieldSet => {
      filterFieldSet.classList.toggle('is-disabled', !enabled)
      const filter = filterFieldSet && filterFieldSet.firstElementChild
      const apiAttribute = filter && filter.getAttribute('data-js-component')
      const api = filter && apiAttribute && filter[apiAttribute]
      if (api) {
        api.setProp('disabled', !enabled)
      }
    })

    return this
  }

  /**
   * Reset Filters
   * - Delete current selected filters
   * - Force an update, including filters
   */
  resetFilters () {
    const defaultAgeProfileBirthDate = this.participantsSelectorAPI
      ? this.participantsSelectorAPI.getDefaultAgeProfileBirthdate()
      : undefined
    if (defaultAgeProfileBirthDate) {
      const numberOfAdults = this.localesData.defaultNumberOfAdults ?? 2
      this.selectedValues = {
        Participants: [Array(numberOfAdults).fill(defaultAgeProfileBirthDate)]
      }
      this.updateApp({ updateFilters: true })
    }
  }

  hideError () {
    if (this.elements.errorSection) {
      flush(this.elements.errorSection)
    }
  }

  /**
   * Lead to unrecoverable error
   * - Prompts user with un-closable modal to reset filters an reload page
   */
  showError (text = undefined, errors = undefined) {
    let modalText = text || 'Seems there\'s an error retrieving prices with current applied filters. Change filters and try again.'
    const messages = errors && errors
      .filter(err => err.message && err.message !== '')
      .map(err => err.message)
    if (messages && messages.length) {
      modalText +=
      '<ul>' +
      messages.map(message => '<li>' + message + '</li') +
      '</ul>'
    }

    const errorMessageHtml = elementFromString(BookingGateErrorTemplate({
      text: modalText
    }))
    if (this.elements.errorSection) {
      moveChildrenFrom(errorMessageHtml, this.elements.errorSection, { flush: true })
      Component.initDocumentComponentsFromAPI(this.elements.errorSection)
    }
  }

  showStartBookingErrorModal (exception) {
    const modalApi = this.elements.startBookingErrorModal
      ? this.elements.startBookingErrorModal[ELEMENT_QUERIES.modalApi]
      : undefined

    this.events.emit(bookingGateEvents.BOOKING_GATE_OPEN_START_BOOKING_ERROR_MODAL, {
      accommodationId: this.queryParams.accoid,
      exception
    })

    if (modalApi) {
      modalApi.setProp('body', this.startBookingErrorModalText)
      modalApi.open()
    } else {
      window.confirm(this.startBookingErrorModalText)
    }
  }

  /*
  * -----------------------------------------------------
  * FILTERS RELATED METHODS
  * -----------------------------------------------------
  * */

  /**
   * Creates a new filters collection, and bind updated event to:
   * - Update SelectedValues
   * - Fire a BookingGate change event
   *
   * @param {Object[]} filtersData - The filters data
   *
   * @returns {FiltersCollection}
   */
  initFiltersCollection (filtersData = []) {
    const filtersCollection = new FiltersCollection(filtersData)
    filtersCollection.events.on('updated', () => {
      const oldSelectedValues = { ...this.selectedValues }
      const newSelectedValues = this.filtersCollection.getFiltersSelectedValuesObject()
      const participantsChanged = JSON.stringify(oldSelectedValues.Participants) !== JSON.stringify(newSelectedValues.Participants)
      this.selectedValues = this.filtersCollection.getFiltersSelectedValuesObject()
      this._sanitizeTransportFilters()
      this.events.emit('filtersChange', {
        participantsChanged,
        oldSelectedValues,
        newSelectedValues
      })
    })
    return filtersCollection
  }

  /**
   * Init or update filters
   *
   * @returns {BookingGate} self instance
   */
  initOrUpdateFilterViews () {
    // Init or update filters
    this.filterViews = this.filterViews || []
    this.elements.filters.forEach(el => {
      // Get the element filter view, and check if it's known & available
      const filterView = el.getAttribute(ELEMENT_QUERIES.filterViewAttr)
      if (!KNOWN_FILTER_VIEWS[filterView]) return

      // Check the filter name
      const filterName = el.getAttribute(ELEMENT_QUERIES.filterNameAttr) ||
        el.getAttributeNames().filter((attrKey) => attrKey.indexOf(ELEMENT_QUERIES.filterNameAttr) === 0)

      // Get the element filter data, and check if it's available
      const filterData = !Array.isArray(filterName)
        ? this.filtersCollection.findWhere('type', filterName)
        : filterName.map((attrKey) => [
          toCamelCase(attrKey.replace(`${ELEMENT_QUERIES.filterNameAttr}-`, '')),
          el.getAttribute(attrKey)
        ]).reduce((obj, [attrKey, attrValue]) => ({
          ...obj,
          [attrKey]: this.filtersCollection.findWhere('type', attrValue)
        }), {})
      if (!filterData) return

      // Init or update the view with associated data if necessary
      const name = !Array.isArray(filterName) ? filterName : filterName.map((attrKey) => el.getAttribute(attrKey)).join('_')
      const filterViewObject = this.filterViews.find(v => v.name === name && v.view === filterView)
      filterViewObject
        ? filterViewObject.instance.setFilterModel(filterData)
        : this.filterViews.push({
          name,
          view: filterView,
          instance: new KNOWN_FILTER_VIEWS[filterView](el, filterData),
          data: filterData
        })
    })
    return this
  }

  /**
   * Check if month siblings are applicable or not (has data to move back and forward using price table arrows)
   *
   * @param {Number} monthsOffset - Number of months to offset, positive or negative
   *
   * @returns {Boolean}
   */
  checkOffsetMonthAvailable (monthsOffset) {
    const monthFilter = this.filtersCollection.findWhere('type', FILTER_TYPES.MONTH)
    if (!monthFilter) return false
    return monthFilter.checkOffsetAvailable(monthsOffset)
  }

  /**
   * Offset Month filter by given amount of months
   *
   * @param {Number} monthsOffset - Number of months to offset, positive or negative
   * @param {ModelActionOptions} [options={}]
   */
  offsetMonthFilter (monthsOffset, options = {}) {
    const monthFilter = this.filtersCollection.findWhere('type', FILTER_TYPES.MONTH)
    if (!monthFilter) return false
    return monthFilter.offsetSelected(monthsOffset, options)
  }

  /**
   * Shows or hides DepartureAirport Filter according the value of transportType
   */
  toggleDepartureAirportVisibility () {
    this.departureAirportFilter = this.departureAirportFilter || this.element.querySelector(ELEMENT_QUERIES.departureAirportFilter)
    if (this.departureAirportFilter) {
      const shouldBeVisible = (
        !(FILTER_TYPES.TRANSPORT_TYPE in this.selectedValues) ||
        this.selectedValues.TransportType === TRANSPORT_TYPE_VALUES.FLIGHT
      )
      this.departureAirportFilter.classList[shouldBeVisible ? 'remove' : 'add'](HIDDEN_CLASSNAME)
    }
  }

  /**
   * Shows or hides ArrivalAirport Filter according the value of transportType
   */
  toggleArrivalAirportVisibility () {
    this.arrivalAirportFilter = this.arrivalAirportFilter || this.element.querySelector(ELEMENT_QUERIES.arrivalAirportFilter)
    if (this.arrivalAirportFilter) {
      const shouldBeVisible = (
        !(FILTER_TYPES.TRANSPORT_TYPE in this.selectedValues) ||
        this.selectedValues.TransportType === TRANSPORT_TYPE_VALUES.FLIGHT
      )
      this.arrivalAirportFilter.classList[shouldBeVisible ? 'remove' : 'add'](HIDDEN_CLASSNAME)
    }
  }

  toggleRoomTypeFilterVisibility () {
    const numberOfParticipants = this.getParticipantsDataFromComponent().Participants
    const participantsSelectorNumberOfRooms = numberOfParticipants ? numberOfParticipants.length : 1

    this.roomTypeFilter = this.roomTypeFilter || this.element.querySelector(ELEMENT_QUERIES.roomTypeFilter)
    if (!this.roomTypeFilter) return

    const shouldBeHidden = participantsSelectorNumberOfRooms > 1
    this.roomTypeFilter.classList[shouldBeHidden ? 'add' : 'remove'](HIDDEN_CLASSNAME)
  }

  _sanitizeTransportFilters () {
    const transportTypeFilter = Array.from(this.elements.filters).find(f => f.getAttribute(ELEMENT_QUERIES.filterNameAttr) === FILTER_TYPES.TRANSPORT_TYPE)
    if (transportTypeFilter && this.selectedValues.TransportType !== TRANSPORT_TYPE_VALUES.FLIGHT) {
      this.selectedValues.DepartureAirport = undefined
      this.selectedValues.ArrivalAirport = undefined
    }
  }

  /*
  * -----------------------------------------------------
  * PRICE TABLE RELATED METHODS
  * -----------------------------------------------------
  * */

  /**
   * Init as much price tables as needed
   *
   * @arg {Object[]} priceTableData - BookingData objects to hydrate PT
   *
   * @returns {PriceTable[]} - Price table instances
   */
  initPriceTables (priceTableData = []) {
    const oldPriceTables = this.priceTables || []
    const priceTableContainer = this.elements.priceTableContainer
    const locales = this.getLocalesForPriceTableData()

    // Unbind remainder/exceed priceTables that will be removed from DOM
    for (let i = priceTableData.length; i < oldPriceTables.length; i++) {
      this._unbindPriceTable(oldPriceTables[i])
    }

    // Create the newPriceTables object
    // - Hydrating the existing ones with fresh data
    // - Creating missing ones with fresh data
    const newPriceTables = priceTableData.map((data, i) => {
      // Use the existing element & instance if exists
      if (oldPriceTables[i]) {
        oldPriceTables[i].refresh({
          data,
          prevMonth: this.checkOffsetMonthAvailable(-1),
          nextMonth: this.checkOffsetMonthAvailable(1),
          origin: this.priceTableOrigin,
          locales
        })
        return oldPriceTables[i]
      }
      // Create a new element
      const element = document.createElement('div')
      element.classList.add('w-booking-gate__price-table')
      element.id = `${this.element.id}-price-table-${i}`
      const newPriceTable = new PriceTable(element, {})
      this._bindPriceTable(newPriceTable)
      priceTableContainer.appendChild(newPriceTable.element)
      newPriceTable.refresh({
        data,
        prevMonth: this.checkOffsetMonthAvailable(-1),
        nextMonth: this.checkOffsetMonthAvailable(1),
        origin: this.priceTableOrigin,
        locales
      })
      return newPriceTable
    })
    // After refresh, reset the price table origin value (MM-3561)
    this.priceTableOrigin = null

    // Update the DOM elements according the new amount of priceTables needed to be shown
    // - Removing the remainder/exceed elements
    // - Appending the missing elements
    if (priceTableContainer.childNodes.length >= newPriceTables.length) {
      while (priceTableContainer.childNodes.length > newPriceTables.length) {
        priceTableContainer.removeChild(priceTableContainer.lastChild)
      }
    }

    return newPriceTables
  }

  /**
   * Offset price tables to given date
   *
   * @param {DateString} date
   * @param {Object} options
   */
  offsetPriceTablesToDate (date, options = {}) {
    this.priceTables.forEach(priceTable => {
      priceTable.goTo(date, options)
      priceTable.updateArrows()
    })
  }

  /**
   * Fetch new data and update price table(s) with it
   *
   * @param {Object} options - Update options
   * @param {DateString} options.departureDate - If any, will offset columns to chosen date
   *
   * @returns {Promise}
   */
  async updatePriceTable (options = {}) {
    this.participantsProfiles = []

    if (this.priceTableType === PriceTableType.DepartureDateAndRoomType) {
      return this._updatePriceTableGroupedByRoomType(options)
    } else if (this.priceTableType === PriceTableType.DepartureDateAndDuration) {
      return this._updatePriceTableGroupedByDuration(options)
    } else {
      console.log(`price table type '${this.priceTableType}' not supported`)
    }
  }

  async addMonthPriceTable (options = {}) {
    if (this.priceTableType === PriceTableType.DepartureDateAndDuration) {
      return this._addMonthPriceTableGroupedByDuration(options)
    } else {
      console.log(`price table type '${this.priceTableType}' not supported`)
    }
  }

  /**
   * Fetch new data and update price table(s) grouped by room type (aka Price Table 1.0) with it.
   *
   * @param {Object} options - Update options
   * @param {DateString} options.departureDate - If any, will offset columns to chosen date
   *
   * @returns {Promise}
   */
  async _updatePriceTableGroupedByRoomType (options = {}) {
    const { departureDate } = options
    const priceTableData = await this.apiPriceTable.fetch(
      removeUndefinedKeys({
        ...this.queryParams,
        ...this.selectedValues,
        ...this.passThroughParams
      })
    )
    if (priceTableData.errors && priceTableData.errors.length) {
      this.priceTables = this.initPriceTables()
      const errorText = this.errorModalText || this.locales.pricetableError
      this.showError(errorText, priceTableData.errors)
      this.events.emit(bookingGateEvents.BOOKING_GATE_ERRORS_THROWN, {
        origin: 'FetchPriceTableGroupedByRoomType',
        errors: priceTableData.errors
      })
      const errorMessage = 'Errors found on price-table API'
      if (window.newrelic) {
        window.newrelic.noticeError(errorMessage)
      }
      throw new Error(errorMessage)
    }

    this.participantsProfiles = priceTableData.participantsProfiles

    if (this.apiPriceTable.hasAlternatives()) {
      // Flush price tables by invoking with empty data
      this.priceTables = this.initPriceTables()
      // Flush booking gate messages
      flush(this.elements.priceTableMessages)
      // Append & bind alternatives into noResults element
      const noResultsHtml = elementFromString(BookingGateNoResultsTemplate({
        ...this.localesData.alternativeFilters,
        alternatives: this.apiPriceTable.data.alternatives
      }))
      moveChildrenFrom(noResultsHtml, this.elements.noResults, { flush: true })
      Component.initDocumentComponentsFromAPI(this.elements.noResults)
      this._attachChipAlternativeEvents()
    } else {
      flush(this.elements.noResults)
      if (this.apiPriceTable.data.messages && this.apiPriceTable.data.messages.length > 0 && this.elements.priceTableMessages) {
        this.elements.priceTableMessages.innerHTML = BookingGateMessagesTemplate({ messages: this.apiPriceTable.data.messages })
      }
      this.priceTables = this.initPriceTables(
        this.apiPriceTable.getDataByDurations()
      )

      if (!isElementInViewport(this.element)) {
        this.element.scrollIntoView(false)
      }

      if (departureDate) this.offsetPriceTablesToDate(departureDate, { silent: true })
    }
  }

  /**
   * Fetch new data and update price table(s) grouped by duration (aka Price Table 2.0) with it.
   *
   * @param {Object} options - Update options (currently unused)
   *
   * @returns {Promise}
   */
  async _updatePriceTableGroupedByDuration (options = {}) {
    this.priceTableState = {
      firstMonthShownOffset: 0,
      lastMonthShownOffset: 0,
      prices: [],
      bestValues: []
    }

    // Fetch data from the Sitecore API.
    const params = removeUndefinedKeys({
      ...this.queryParams,
      ...this.selectedValues,
      ...this.passThroughParams
    })
    const priceTableData = await this.apiPriceTableGroupedByDuration.fetch(params)

    if (priceTableData.data && priceTableData.data.prices && priceTableData.data.prices.length) {
      this.priceTableState = {
        firstMonthShownOffset: 0,
        lastMonthShownOffset: 0,
        prices: priceTableData.data.prices,
        bestValues: priceTableData.data.bestValues
      }
      // The currency settings are required later, when handling the price table 2.0 cell selected,
      // as the DDGrid does not return the currency symbol as happens with price table 1.0.
      this.currency = priceTableData.data.currency
      this.participantsProfiles = priceTableData.data.participantsProfiles

      // Render template with the fetched data.
      const props = {
        bestValues: priceTableData.data.bestValues,
        bestValuesCaption: priceTableData.data.bestValuesCaption,
        mandatoryExtraCostsText: priceTableData.data.mandatoryExtraCostsText,
        staticText: priceTableData.data.staticText,
        prices: priceTableData.data.prices,
        highlightInfo: priceTableData.data.highlightInfo,
        texts: {
          ...DDGrid.getLocales(),
          ...this._getLocalesForPriceTableGroupedByDurationData()
        },
        showDurationInNights: this.showDurationInNights,
        showAddNextMonth: this.priceTableShowAddNextMonthButton
      }
      const ddGridElement = elementFromString(DDGridTemplate(props))

      if (this.priceTableByDuration) {
        this._unbindPriceTable(this.priceTableByDuration)
      }
      this._destroyPriceTableByDuration()

      const options = {
        prevMonthEnabled: this.checkOffsetMonthAvailable(-1),
        nextMonthEnabled: this.checkOffsetMonthAvailable(1),
        addPreviousMonthEnabled: this.checkOffsetMonthAvailable(this.priceTableState.firstMonthShownOffset - 1),
        addNextMonthEnabled: this.checkOffsetMonthAvailable(this.priceTableState.lastMonthShownOffset + 1),
        origin: this.priceTableOrigin
      }

      this.priceTableByDuration = new DDGrid(ddGridElement, options)
      this._bindPriceTable(this.priceTableByDuration)
      if (priceTableData.data.messages && priceTableData.data.messages.length > 0 && this.elements.priceTableMessages) {
        this.elements.priceTableMessages.innerHTML = BookingGateMessagesTemplate({ messages: priceTableData.data.messages })
      }
      this.elements.priceTableContainer.appendChild(ddGridElement)
      this.priceTableByDuration.refreshScrollPosition()
    } else if (priceTableData.data && (priceTableData.data.isEmptyResponse || Object.keys(priceTableData.data.alternateFacets).length)) {
      this._destroyPriceTableByDuration()

      // Append & bind alternatives into noResults element
      const noResultsHtml = elementFromString(BookingGateNoResultsTemplate({
        ...this.localesData.alternativeFilters,
        alternatives: priceTableData.data.alternateFacets
      }))

      moveChildrenFrom(noResultsHtml, this.elements.noResults, { flush: true })
      Component.initDocumentComponentsFromAPI(this.elements.noResults)
      this._attachChipAlternativeEvents()
    } else if (priceTableData.errors && priceTableData.errors.length) {
      const errMsg = 'Could not retrieve price table grouped by duration'
      console.error(errMsg, priceTableData.errors)
      this.priceTables = this.initPriceTables()
      const errorText = this.errorModalText || this.locales.pricetableError
      this.showError(errorText, priceTableData.errors)
      this.events.emit(bookingGateEvents.BOOKING_GATE_ERRORS_THROWN, {
        origin: 'FetchPriceTableGroupedByDuration',
        message: errMsg,
        errors: priceTableData.errors
      })
      throw new Error('Errors found on price-table API')
    } else {
      const errMsg = 'Unknown price table grouped by duration API result'
      console.error(errMsg, priceTableData)
      this.priceTables = this.initPriceTables()
      const errorText = this.errorModalText || this.locales.pricetableError
      this.showError(errorText, priceTableData.errors)
      this.events.emit(bookingGateEvents.BOOKING_GATE_ERRORS_THROWN, {
        origin: 'PriceTableGroupedByDuration',
        message: errMsg,
        errors: []
      })
      throw new Error('Errors found on price-table API')
    }
  }

  async _addMonthPriceTableGroupedByDuration (monthToAdd, options = {}) {
    // Fetch data from the Sitecore API.
    const params = removeUndefinedKeys({
      ...this.queryParams,
      ...this.selectedValues,
      ...this.passThroughParams,
      Month: monthToAdd.departureDate
    })
    const priceTableData = await this.apiPriceTableGroupedByDuration.fetch(params)

    if (priceTableData.data && priceTableData.data.prices && priceTableData.data.prices.length) {
      this.priceTableState.bestValues = [...this.priceTableState.bestValues, ...priceTableData.data.bestValues]
      this.priceTableState.bestValuesCaption = this.priceTableState.bestValuesCaption || priceTableData.data.bestValuesCaption
      this.priceTableState.mandatoryExtraCostsText = this.priceTableState.mandatoryExtraCostsText || priceTableData.data.mandatoryExtraCostsText
      this.priceTableState.staticText = this.priceTableState.staticText || priceTableData.data.staticText
      this.priceTableState.prices = [...this.priceTableState.prices, ...priceTableData.data.prices]

      let scrollPosition
      if (this.priceTableByDuration) {
        scrollPosition = this.priceTableByDuration.getScrollPosition()
        this._unbindPriceTable(this.priceTableByDuration)
      }
      this._destroyPriceTableByDuration()

      const props = {
        bestValues: this.priceTableState.bestValues,
        bestValuesCaption: this.priceTableState.bestValuesCaption,
        mandatoryExtraCostsText: this.priceTableState.mandatoryExtraCostsText,
        staticText: this.priceTableState.staticText,
        prices: this.priceTableState.prices,
        texts: {
          ...DDGrid.getLocales(),
          ...this._getLocalesForPriceTableGroupedByDurationData()
        },
        showDurationInNights: this.showDurationInNights,
        showAddNextMonth: this.priceTableShowAddNextMonthButton
      }
      const ddGridElement = elementFromString(DDGridTemplate(props))

      const options = {
        prevMonthEnabled: this.checkOffsetMonthAvailable(-1),
        nextMonthEnabled: this.checkOffsetMonthAvailable(1),
        addPreviousMonthEnabled: this.checkOffsetMonthAvailable(this.priceTableState.firstMonthShownOffset - 1),
        addNextMonthEnabled: this.checkOffsetMonthAvailable(this.priceTableState.lastMonthShownOffset + 1),
        origin: this.priceTableOrigin
      }

      this.priceTableByDuration = new DDGrid(ddGridElement, options)
      this._bindPriceTable(this.priceTableByDuration)
      this.elements.priceTableContainer.appendChild(ddGridElement)
      this.priceTableByDuration.refreshScrollSpecificPosition(scrollPosition)
    } else if (priceTableData.data && Object.keys(priceTableData.data.alternateFacets).length) {
      // alternate facets shouldn't be shown when we are attached price table
    } else if (priceTableData.errors && priceTableData.errors.length) {
      const errMsg = 'Could not retrieve price table grouped by duration'
      console.error(errMsg, priceTableData.errors)
      this.events.emit(bookingGateEvents.BOOKING_GATE_ERRORS_THROWN, {
        origin: 'FetchPriceTableGroupedByDuration',
        message: errMsg,
        errors: priceTableData.errors
      })
    } else {
      const errMsg = 'Unknown price table grouped by duration API result'
      console.error(errMsg, priceTableData)
      this.events.emit(bookingGateEvents.BOOKING_GATE_ERRORS_THROWN, {
        origin: 'PriceTableGroupedByDuration',
        message: errMsg,
        errors: []
      })
    }
  }

  /**
   * Get the locales (translation) required by the price table grouped by duration.
   *
   * @returns {Object} Collection of translations required for the price table grouped by duration.
   */
  _getLocalesForPriceTableGroupedByDurationData () {
    if (this.localesData.priceTableGroupedByDuration) {
      return {
        durationInDaysText: this.localesData.priceTableGroupedByDuration.durationInDaysText,
        durationInNightsText: this.localesData.priceTableGroupedByDuration.durationInNightsText,
        navigationNavText: this.localesData.priceTableGroupedByDuration.navigationNavText
      }
    }
  }

  /**
   * Bind price table events
   * - Months navigation events (coordinated with other priceTables on document)
   *
   * @param {PriceTable} priceTable - PriceTable instance
   */
  _bindPriceTable (priceTable) {
    priceTable.events.on('move', this._handlePriceTableMove, this)
    priceTable.events.on('prevEdge', this._handlePriceTableEdge, this)
    priceTable.events.on('nextEdge', this._handlePriceTableEdge, this)
    priceTable.events.on('addMonth', this._handlePriceTableAddMonth, this)
    priceTable.events.on('dateSelected', this._handlePriceTableDateSelected, this) // Only for price table grouped by room type (Price Table 1.0).
    priceTable.events.on('priceSelected', this._handlePriceTablePriceSelected, this) // Only for price table grouped by duration (Price Table 2.0).
  }

  /**
   * Unbind price table events
   *
   * @param{PriceTable} priceTable - PriceTable instance
   */
  _unbindPriceTable (priceTable) {
    priceTable.events.removeListener('move', this._handlePriceTableMove, this)
    priceTable.events.removeListener('prevEdge', this._handlePriceTableEdge, this)
    priceTable.events.removeListener('nextEdge', this._handlePriceTableEdge, this)
    priceTable.events.removeListener('addMonth', this._handlePriceTableAddMonth, this)
    priceTable.events.removeListener('dateSelected', this._handlePriceTableDateSelected, this) // Only for price table grouped by room type (Price Table 1.0).
    priceTable.events.removeListener('priceSelected', this._handlePriceTablePriceSelected, this) // Only for price table grouped by duration (Price Table 2.0).
  }

  /**
   * Handle priceTable move, should move the other priceTables to keep them synchronized
   */
  _handlePriceTableMove (eventArg) {
    this.priceTables
      .forEach(priceTable => {
        priceTable.toggleNavVisibility(true)
      })

    if (this.priceTableType === PriceTableType.DepartureDateAndRoomType) {
      this.removeBookingGateItemWrapper()
    }

    this.priceTables
      .filter(priceTable => priceTable !== eventArg.instance)
      .forEach(priceTable => priceTable.move(eventArg.direction, null, { silent: true }))
  }

  /**
   * Handle priceTable edge, should update months filter
   */
  _handlePriceTableEdge (eventArg) {
    const { direction } = eventArg
    this.priceTableOrigin = direction === 'prev' ? 'end' : null
    const monthsOffset = direction === 'prev' ? -1 : 1
    this.offsetMonthFilter(monthsOffset)
    this.priceTables
      .forEach(priceTable => {
        priceTable.toggleNavVisibility(true)
      })
    this.removeBookingGateItemWrapper()
  }

  /**
   * Handle priceTable edge, shouldn't update months filter
   */
  _handlePriceTableAddMonth (eventArg) {
    const { direction } = eventArg

    // get month filter
    const monthFilter = this.filtersCollection.findWhere('type', FILTER_TYPES.MONTH)
    if (!monthFilter) return

    // calculate offset from current month
    let currentOffset
    if (direction === 'prev') {
      this.priceTableState.firstMonthShownOffset -= 1
      currentOffset = this.priceTableState.firstMonthShownOffset
    } else if (direction === 'next') {
      this.priceTableState.lastMonthShownOffset += 1
      currentOffset = this.priceTableState.lastMonthShownOffset
    } else {
      console.log('invalid direction to add month. accepted values are [prev|next]')
      return
    }

    const monthsOffset = currentOffset

    // get month value for the offset needed
    const monthToAdd = monthFilter.getValueByOffset(monthsOffset)

    // debounce price table
    // TODO Peding to fix the loading
    this.priceTableByDuration.refreshLoadingAddMonthElement(true)

    // append data to current price table
    return this.addMonthPriceTable({ direction, departureDate: monthToAdd })
      .then(() => {
        this.setEnabledState(true)
        Promise.resolve()
      })
  }

  /**
   * Handle priceTable cell selected, shows the room selector
   * Only applies to price table grouped by room type (Price Table 1.0)
   */
  async _handlePriceTableDateSelected (eventArg) {
    this.cleanUpBookingGateItemWrapper()
    if (this.elements.bookingGateItemWrapper) {
      this.priceTables
        .filter(priceTable => priceTable !== eventArg.instance)
        .forEach(priceTable => priceTable._unselectPriceCell())
    }

    const { selectedPackage, selectedRowElement, priceClickedData } = eventArg
    this.selectedPackage = selectedPackage
    this.priceClickedData = priceClickedData

    this.priceTables
      .forEach(priceTable => {
        priceTable.toggleNavVisibility(false)
      })

    this.initBookingGateItemWrapper()

    selectedRowElement.after(this.elements.bookingGateItemWrapper)

    const isFlightOnly = this.selectedPackage.isFlightOnly ?? false
    if (isFlightOnly) {
      // IF FLIGHT ONLY ==> GO TO FLIGHT SELECTOR
      const participants = this._getParticipantsForFlightOnly()
      this.allocatedRooms = {
        Participants: [participants],
        room: [this.selectedPackage.roomCode],
        roomContractId: undefined
      }
      this.initFlightBusSelector()
      this.initSelectedState()
      this.updateFlightBusSelector()
    } else {
      await this.initRoomSelector(selectedPackage.duration, selectedPackage.departureDate, selectedPackage.roomCode, selectedPackage.roomId, selectedPackage.roomContractId, selectedPackage.occupation)
    }

    this.emitPriceCellClickedEvent()
  }

  /**
   * Handle priceTable cell selected, shows the room selector
   * Only applies to price table grouped by duration (Price Table 2.0)
   */
  async _handlePriceTablePriceSelected (eventArg) {
    this.cleanUpBookingGateItemWrapper()

    const { duration, departure, price, transportType, isFlightOnly, mealplan, bestValueScore, selectedPriceCell } = eventArg
    this.selectedPriceCell = selectedPriceCell
    // This 'priceClickeData' property is used within the datalayers events, for price table 1.0
    // it is returned by the cell click event, but for price table 2.0 it must be constructed .
    this.priceClickedData = {
      price,
      currencyCode: this.currency.symbol,
      duration,
      departureDate: departure,
      isPriceHighlighted: bestValueScore > this.priceTableState?.bestValues?.[0]?.threshold
    }

    this.initBookingGateItemWrapper()
    this.scrollToBookingGateItemWrapper()

    this.priceTableByDuration.element.after(this.elements.bookingGateItemWrapper)

    if (isFlightOnly) {
      // IF FLIGHT ONLY ==> GO TO FLIGHT SELECTOR
      this.selectedPackage = {
        transportType,
        departureDate: departure,
        duration,
        packageFilter: {
          mealplan
        }
      }
      const participants = this._getParticipantsForFlightOnly()
      this.allocatedRooms = {
        Participants: [participants],
        room: [FLIGHT_ONLY_DEFAULT_ROOM_CODE],
        roomContractId: undefined
      }
      this.initFlightBusSelector()
      this.initSelectedState()
      this.updateFlightBusSelector()
    } else {
      // ELSE (ACCO) ==> GO TO ROOM SELECTOR
      await this.initRoomSelector(duration, departure)
    }

    this.emitPriceCellClickedEvent()
  }

  emitPriceCellClickedEvent () {
    this.events.emit(bookingGateEvents.BOOKING_GATE_PRICE_CELL_CLICKED, {
      selectedValues: this._getSelectedValuesForTracking(),
      priceClickedData: this.priceClickedData,
      totalOccupation: this._getTotalOccupation(),
      isPromotedPriceOpened: this.elements.promotedPrice ? this.elements.promotedPrice.classList.contains('in') : false
    })
  }

  emitPriceTableViewed () {
    this.events.emit(bookingGateEvents.BOOKING_GATE_PRICE_TABLE_VIEWED, {
      selectedValues: this._getSelectedValuesForTracking(),
      priceClickedData: this.priceClickedData,
      totalOccupation: this._getTotalOccupation(),
      isPromotedPriceOpened: this.elements.promotedPrice ? this.elements.promotedPrice.classList.contains('in') : false,
      originalDepartureDate: this.promotedPrice ? this.promotedPrice.getOriginalDepartureDate() : ''
    })
  }

  scrollToBookingGateItemWrapper () {
    if (!this.elements.bookingGateItemWrapper) return
    const targetPriceTable = this.priceTableByDuration
    if (targetPriceTable) {
      const documentScrollingElement = document.scrollingElement || document.documentElement
      const priceTableBoundingRect = targetPriceTable.element.getBoundingClientRect()
      const scroll = priceTableBoundingRect.top + priceTableBoundingRect.height + documentScrollingElement.scrollTop
      smooth(documentScrollingElement, 0, scroll)
    }
  }

  getLocalesForPriceTableData () {
    return {
      lastRoomAvailable: this.localesData.roomSelector.lastRoomAvailable,
      roomsLeftAvailable: this.localesData.roomSelector.roomsLeftAvailable,
      onRequestLabel: this.localesData.roomSelector.onRequestLabel,
      adultWord: this.localesData.roomSelector.adultWord,
      adultsWord: this.localesData.roomSelector.adultsWord,
      childWord: this.localesData.roomSelector.childWord,
      childrenWord: this.localesData.roomSelector.childrenWord,
      babyWord: this.localesData.roomSelector.babyWord,
      babiesWord: this.localesData.roomSelector.babiesWord,
      roomStockThresholdToShowLowAvailability: this.localesData.roomSelector.roomStockThresholdToShowLowAvailability,
      moreInfo: this.localesData.moreInfo
    }
  }

  /**
   * Init out of season
   * - It's an extra option close to months selector to show a subscription form for next season
   * - Requires some hidden attributes on months tabs filter
   * - Requires a hidden content element (server side rendered) with the form to be shown
   *
   * @typedef {Object} OutOfSeasonObject
   * @property {Boolean} shouldBeShown - Will be true if requirements were met
   * @property {String} label - The text of the call to action
   * @property {HTMLElement} element - The extra option element to inject on months tabs (binded)
   * @property {HTMLElement} linkElement - The anchor tag in element to toggle active classes later on
   * @property {HTMLElement} outOfSeasonModal - The hidden modal form container to be shown/hide
   * @property {FilterObject} monthsTabsFilter - The months filter (TAB selector)
   *
   * @returns {OutOfSeasonObject}
   */
  initOutOfSeason () {
    const monthsTabsFilter = this.filterViews.find(view => view.name === 'Month' && view.view === 'tabs')

    const monthsTabsFilterElement = monthsTabsFilter && monthsTabsFilter.instance.element

    const outOfSeasonModal = this.elements.outOfSeasonModal
      ? this.elements.outOfSeasonModal[ELEMENT_QUERIES.modalApi]
      : undefined

    const shouldBeShown = this.elements.outOfSeasonModal && this.elements.outOfSeasonModal.matches('[data-w-subscribe-form-modal__button-text]')
    if (!shouldBeShown) return undefined

    const label = this.elements.outOfSeasonModal.getAttribute(ELEMENT_QUERIES.outOfSeasonModalButtonText)
    const modalId = this.elements.outOfSeasonModal.getAttribute(ELEMENT_QUERIES.outOfSeasonModalId)
    const element = elementFromString(NEXT_SEASON_ITEM_TEMPLATE({ label, modalId }))
    const linkElement = element.querySelector(ELEMENT_QUERIES.outOfSeasonModalButton)

    // Inject on TABS
    const targetList = monthsTabsFilterElement.querySelector('.c-tabs__nav.c-tabs__nav--secondary')
    if (!targetList) return
    if (!targetList.querySelector(ELEMENT_QUERIES.outOfSeasonModalButton)) targetList.appendChild(element)

    linkElement.addEventListener('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      outOfSeasonModal.open()
    })
  }

  /*
  * -----------------------------------------------------
  * NO RESULTS RELATED METHODS (ALTERNATIVES)
  * -----------------------------------------------------
  */

  _attachChipAlternativeEvents () {
    const chipWrappers = this.elements.noResults.querySelectorAll(ELEMENT_QUERIES.chipWrapperElement)
    if (chipWrappers) {
      chipWrappers.forEach(chipWrapper => chipWrapper.addEventListener('click', (ev) => {
        const chip = ev.target && ev.target.closest(ELEMENT_QUERIES.chipElement)
        const { value, filterName } = chip.dataset
        if (value && filterName) {
          const filter = this.filtersCollection.findWhere('type', CORRECT_FILTER_NAMES[filterName]) // FIXME: filter name mapping
          if (filter) {
            if (filterName === 'durations') {
              // Most of the times the duration values of the alternative filters doesn't have all the duration values of the filter,
              // for this reason we have to check which duration filter contains the range of values selected
              const durationsFilters = filter.values.models.map(model => model.getAttribute('value'))
              const durationsValue = durationsFilters.filter(durationsFilter => durationsFilter.split(',').some(durationValue => value.split(',').includes(durationValue)))
              filter.clearSelection({ silent: true }).setSelectedValues(durationsValue)
            } else {
              filter.clearSelection({ silent: true }).setSelectedValues([value])
            }
          }
        } else if (filterName === 'reset') {
          this.resetFilters()
        }
      }))
    }
  }

  /*
  * -----------------------------------------------------
  * PROMOTED PRICE RELATED METHODS
  * -----------------------------------------------------
  */

  initPromotedPrice () {
    if (!this.elements.promotedPrice) return undefined
    const promotedPriceApi = this.elements.promotedPrice['w-promoted-price']
    if (!promotedPriceApi) return undefined
    promotedPriceApi.events.on('promotedPriceClicked', this._handlePromotedPriceClicked, this)

    this._initStickyPromotedPrice()

    return promotedPriceApi
  }

  /**
   * Handle promoted price clicked, should update months filter
   */
  _handlePromotedPriceClicked (eventArg) {
    const isPricesTab = this.focusCurrentTab()
    this._showStickyPromotedPrice({ isPricesTab })

    if (!eventArg) {
      return
    }

    const DepartureDate = eventArg.departureDate.raw
    const Duration = eventArg.duration
    const Price = eventArg.price.value
    const RawPrice = eventArg.price.rawValue

    // Handle scroll and focus price on price table 1.0
    const scrollAndFocusPriceForPriceTableByDepartureDate = () => {
      if (!Duration) return
      const targetPriceTable = this.priceTables.find(pTable => pTable.options.data.duration === Duration)
      if (targetPriceTable) {
        try {
          const priceCandidates = targetPriceTable.element
            .querySelectorAll(`.c-price-table__cell[data-id$="${DepartureDate}"]`)
          const priceCandidate = [...priceCandidates]
            .find(cell => cell.querySelector('.c-price__value').innerText.includes(Price))
          if (priceCandidate) priceCandidate.click()
        } catch (e) { }
        const documentScrollingElement = document.scrollingElement || document.documentElement
        const priceTableBoundingRect = targetPriceTable.element.getBoundingClientRect()
        const safeMargin = 32
        smooth(
          documentScrollingElement,
          documentScrollingElement.scrollLeft,
          priceTableBoundingRect.top + documentScrollingElement.scrollTop - safeMargin
        )
      }
    }

    // Handle scroll and focus price on price table 2.0
    const scrollAndFocusPriceForPriceTableByDuration = () => {
      if (!Price) return
      const targetPriceTableByDurationCells = this.priceTableByDuration.elements.priceCells
      const targetPriceTableByDurationTarget = targetPriceTableByDurationCells.find(pTableCell => pTableCell.dataset.price === `${RawPrice}`)
      if (!targetPriceTableByDurationTarget) {
        return
      }
      this.priceTableByDuration.elements.panorama.scrollLeft = targetPriceTableByDurationTarget.offsetLeft - targetPriceTableByDurationTarget.getBoundingClientRect().width
      this.priceTableByDuration.applyHoverOnCell(targetPriceTableByDurationTarget)
      const documentScrollingElement = document.scrollingElement || document.documentElement
      const tabs = this.element.querySelector('.w-filter--tabs')
      const scroll = documentScrollingElement.scrollTop + tabs.getBoundingClientRect().top
      smooth(documentScrollingElement, 0, scroll)
    }

    if (this.priceTableType === PriceTableType.DepartureDateAndRoomType) {
      this.offsetPriceTablesToDate(DepartureDate, { silent: true })
      if (this.autoScrollEnabled) {
        scrollAndFocusPriceForPriceTableByDepartureDate()
      }
    } else {
      if (this.autoScrollEnabled) {
        scrollAndFocusPriceForPriceTableByDuration()
      }
    }
  }

  _initStickyPromotedPrice () {
    const pricesTabContent = this.element.closest('.c-tabs__content')
    const accoTabApi = pricesTabContent.closest('[data-js-component="c-tabs"]')['c-tabs']

    accoTabApi.events.on(tabsEvents.TAB_CHANGED, (ev) => {
      const isPriceTab = this._isPriceTabOpen()
      this._handleTabsClicked({ ...ev, isPriceTab })
    }, this)
  }

  _handleTabsClicked (eventArg) {
    this._showStickyPromotedPrice({ isPricesTab: eventArg.isPriceTab })
  }

  _showStickyPromotedPrice (context) {
    if (this.elements.promotedPrice) {
      const promotedPriceApi = this.elements.promotedPrice['w-promoted-price']
      promotedPriceApi?.showStickyPromotedPrice(context)
    }
  }

  /*
  * -----------------------------------------------------
  * BOOKING GATE ITEM WRAPPER RELATED METHODS
  * (Container for RoomSelector, FlightSelector, SelectedState, ...)
  * -----------------------------------------------------
  */

  initBookingGateItemWrapper () {
    if (!this.elements.bookingGateItemWrapper) {
      const bookingGateItemWrapper = elementFromString(BookingGateItemTemplate(this.element.id))
      this.elements.bookingGateItemWrapper = bookingGateItemWrapper
      this.elements.roomSelector = bookingGateItemWrapper.querySelector(ELEMENT_QUERIES.roomSelectorElement)
      this.elements.flightBusSelector = bookingGateItemWrapper.querySelector(ELEMENT_QUERIES.flightBusSelectorElement)
      this.elements.selectedState = bookingGateItemWrapper.querySelector(ELEMENT_QUERIES.selectedStateElement)
      this.elements.bookingItemClose = bookingGateItemWrapper.querySelector(ELEMENT_QUERIES.bookingItemCloseElement)

      // For closing the selected package info (room selector, transport selector, selected state...)
      this.elements.bookingItemClose.addEventListener('click', () => this._basketClosed())

      // TODO: maybe here we need to add event listener to new price table???
    }
  }

  _basketClosed () {
    this.events.emit(bookingGateEvents.BOOKING_GATE_CLOSE_BASKET_BUTTON_CLICKED, {
      selectedValues: this._getSelectedValuesForTracking(),
      allocatedPackages: this._getAllocatedPackages(),
      priceClickedData: this.priceClickedData,
      unallocatedOccupation: this._getUnallocatedOccupation(),
      totalOccupation: this._getTotalOccupation()
    })

    this.removeBookingGateItemWrapper()
    this.priceTables
      .forEach(priceTable => {
        priceTable._unselectPriceCell()
        priceTable.toggleNavVisibility(true)
      })
    if (this.priceTableByDuration) {
      this.priceTableByDuration.clearSelectedCell()
    }
  }

  /**
   * Removes wrapper for Room Selector, Flight/bus Selector, Selected state...)
   *
   */
  removeBookingGateItemWrapper () {
    this.selectedPackage = undefined
    if (this.elements.bookingGateItemWrapper) {
      this.elements.bookingGateItemWrapper.remove()
      // this.elements.bookingGateItemWrapper = undefined
    }
  }

  cleanUpBookingGateItemWrapper () {
    if (this.elements.bookingGateItemWrapper) {
      this.removeBookingGateItemWrapper()
      this.elements.flightBusSelector.innerHTML = ''
      this.elements.selectedState.innerHTML = ''
    }
  }

  /*
  * -----------------------------------------------------
  * ROOM SELECTOR RELATED METHODS
  * -----------------------------------------------------
  */

  async initRoomSelector (duration, departureDate, roomCode = null, roomOccupancyId = null, roomContractId = null, occupation = null) {
    const allocation = this.selectedValues.Allocation

    const roomsData = await this.fetchRoomSelectorData({ departureDate, duration, allocation })

    roomsData.data.rooms.map(room => {
      room.selected = this.selectedValues?.RoomType?.includes(room.id)
      return room
    })

    this.elements.bookingGateItemWrapper.style.minHeight = '1px' // Required to force repaint and avoid a visual bug on MS IE11 where the room selector main DIV has 0 height

    this.roomSelector = new RoomSelector(
      this.elements.roomSelector,
      this.selectedValues.Participants,
      roomsData.data,
      {
        locales: this.localesData.roomSelector,
        hasFlexibleAllocation: this.hasFlexibleAllocation,
        allInSameRoom: this.selectedValues.Allocation === '1', // Value 1 means same room.
        priceTableType: this.priceTableType,
        roomContractId,
        roomCode,
        roomOccupancyId,
        occupation
      }
    )

    const allocatedPackages = this.roomSelector.getAllocatedPackagesIfEverybodyIsAllocated()
    if (allocatedPackages) {
      this._participantsAllocated(allocatedPackages, duration, departureDate)
    }

    this.roomSelector.events.on('participantsAllocated', (allocatedPackages) => this._participantsAllocated(allocatedPackages, duration, departureDate))
    this.roomSelector.events.on('participantsUnallocated', this._participantsUnallocated, this)

    this.elements.bookingGateItemWrapper.style.minHeight = null // Required to force repaint and avoid a visual bug on MS IE11 where the room selector main DIV has 0 height
  }

  _participantsAllocated (allocatedPackages, duration, departureDate) {
    this.selectedPackage = {
      ...allocatedPackages[0],
      departureDate,
      duration,
      transportType: this.selectedValues.TransportType || TRANSPORT_TYPE_VALUES.FLIGHT, // In case the transport is not specified, use flight as it's the default.
      packageFilter: {
        mealplan: this.selectedValues.Mealplan
      }
    }

    this.allocatedRooms = {
      Participants: allocatedPackages.map(pkg => pkg.participants),
      room: allocatedPackages.map(pkg => pkg.roomOccupancyId),
      roomContractId: allocatedPackages.map(pkg => pkg.roomContractId)
    }

    this.events.emit(bookingGateEvents.BOOKING_GATE_PARTICIPANTS_ALLOCATED, {
      selectedValues: this._getSelectedValuesForTracking(),
      allocatedPackages,
      priceClickedData: this.priceClickedData,
      totalOccupation: this._getTotalOccupation(),
      isPromotedPriceOpened: this.elements.promotedPrice && this.elements.promotedPrice.classList.contains('in'),
      campaignCode: this.selectedState?.selectedStateDataModel?.data?.campaignCode ?? ''
    })

    // Init child elements if they're not
    this.initFlightBusSelector()
    this.initSelectedState()
    // Update the rest
    this.updateFlightBusSelector()
  }

  _participantsUnallocated () {
    this.selectedPackage = undefined
    this.selectedPriceCell = undefined
    this.allocatedRooms = {}
    if (this.flightBusSelector) this.flightBusSelector.refresh({})
    if (this.selectedState) this.selectedState.refresh({})
  }

  _getSelectedValuesForTracking () {
    let transportType

    if (this.selectedValues && this.selectedValues.TransportType) {
      transportType = this.selectedValues.TransportType
    } else if (this.selectedPackage && this.selectedPackage.transportType) {
      // Not all sites has the transport type filter for this reason we get the data from the selected packages
      transportType = this.selectedPackage.transportType
    }

    let mealplan

    if (this.selectedValues && this.selectedValues.Mealplan) {
      mealplan = this.selectedValues.Mealplan
    } else if (this.selectedPackage && this.selectedPackage.packageFilter && this.selectedPackage.packageFilter.mealplan) {
      mealplan = this.selectedPackage.packageFilter.mealplan
    }

    let duration

    if (this.selectedValues && this.selectedValues.Duration) {
      duration = this.selectedValues.Duration
    } else if (this.selectedPackage && this.selectedPackage.Duration) {
      duration = this.selectedPackage.packageFilter.duration
    }

    return {
      Participants: this.selectedValues ? this.selectedValues.Participants : undefined,
      TransportType: transportType,
      Mealplan: mealplan,
      Duration: duration
    }
  }

  /*
  * -----------------------------------------------------
  * TRANSPORT SELECTOR RELATED METHODS (BUS / FLIGHT)
  * -----------------------------------------------------
  * */

  initFlightBusSelector () {
    if (!this.flightBusSelector) {
      this.flightBusSelector = new FlightBusSelector(this.elements.flightBusSelector, { extraData: this.localesData })

      // Event for flight bus selector. When selected a combination of outbound/inbound flight/bus, overview (selected state) will be loaded
      this.flightBusSelector.events.on('selectedInfo', (flightBusSelectorData) => {
        this.selectedState.refresh(flightBusSelectorData)
        this._sendDataLayerTransportInfo(flightBusSelectorData)
        // TODO: AB test related event, remove after test is completed (MF-2565)
        this.events.emit('selectFlightSelector')
      })
      this.flightBusSelector.events.on('openJawSwitch', (data) => {
        this.events.emit(bookingGateEvents.BOOKING_GATE_OPEN_JAW_SWITCH, {
          value: data.enabled
        })
      })
    }
  }

  _sendDataLayerTransportInfo (flightBusSelectorData) {
    if (this.selectedPackage.transportType.toLowerCase() === TRANSPORT_TYPE_VALUES.BUS.toLowerCase()) {
      if (this.selectedTransportInboundId !== flightBusSelectorData.inboundTransportInfo.busId) {
        this.selectedTransportInboundId = flightBusSelectorData.inboundTransportInfo.busId
        this.events.emit(bookingGateEvents.BOOKING_GATE_BUS_CHANGED, {
          busInfo: flightBusSelectorData.inboundTransportInfo,
          direction: 'inbound'
        })
      }

      if (this.selectedTransportOutboundId !== flightBusSelectorData.outboundTransportInfo.busId) {
        this.selectedTransportOutboundId = flightBusSelectorData.outboundTransportInfo.busId
        this.events.emit(bookingGateEvents.BOOKING_GATE_BUS_CHANGED, {
          busInfo: flightBusSelectorData.outboundTransportInfo,
          direction: 'outbound'
        })
      }
    } else if (this.selectedPackage.transportType.toLowerCase() === TRANSPORT_TYPE_VALUES.FLIGHT.toLowerCase()) {
      if (this.selectedTransportInboundId !== flightBusSelectorData.inboundTransportInfo.flightId) {
        this.selectedTransportInboundId = flightBusSelectorData.inboundTransportInfo.flightId
        this.events.emit(bookingGateEvents.BOOKING_GATE_FLIGHT_CHANGED, {
          flightInfo: flightBusSelectorData.inboundTransportInfo,
          direction: 'inbound'
        })
      }

      if (this.selectedTransportOutboundId !== flightBusSelectorData.outboundTransportInfo.flightId) {
        this.selectedTransportOutboundId = flightBusSelectorData.outboundTransportInfo.flightId
        this.events.emit(bookingGateEvents.BOOKING_GATE_FLIGHT_CHANGED, {
          flightInfo: flightBusSelectorData.outboundTransportInfo,
          direction: 'outbound'
        })
      }
    }
  }

  async updateFlightBusSelector () {
    const {
      transportType,
      departureDate,
      duration
    } = this.selectedPackage

    // FIXME: Check a better way to pass parameters
    const transportData = await this.fetchTransport(
      transportType,
      {
        datefrom: departureDate,
        dateto: departureDate,
        Duration: duration,
        ...this.allocatedRooms
      })

    if (transportType === TRANSPORT_TYPE_VALUES.SELF_DRIVE) {
      const dataModel = new SelfDriveSelectorDataModel(transportData)
      this.flightBusSelector.refresh({})
      this.selectedState.refresh(dataModel.getSelectorData())
    } else {
      this.selectedTransportInboundId = undefined
      this.selectedTransportOutboundId = undefined

      this.flightBusSelector.refresh({
        type: transportType.toLowerCase(),
        data: transportData,
        extraData: this.localesData
      })
    }
  }

  async fetchTransport (type = TRANSPORT_TYPE_VALUES.SELF_DRIVE, params) {
    // TODO: Consider if is needed to disable UI here
    // TODO: Error handling
    const fixedType = fromCamelCase(toCamelCase(type))
    const url = getUrlFromString(
      this.apis[`transport-${fixedType}`],
      arrayifyObject(
        removeUndefinedKeys(
          {
            ...this.queryParams,
            ...this.selectedValues,
            ...params,
            ...this.passThroughParams
          }
        )
      )
    )

    this.setEnabledState(false)
    const dataAndStatus = await fetchJsonDataAndStatusInfo(url, { fullReferrerOnCrossOrigin: true })
    this.setEnabledState(true)

    return dataAndStatus.jsonData
  }

  async fetchRoomSelectorData (selectedPackage) {
    this.setEnabledState(false)

    const params = {
      DepartureDate: selectedPackage.departureDate,
      Duration: selectedPackage.duration,
      PriceTableId: this.queryParams.id,
      Allocation: selectedPackage.allocation
    }

    const url = getUrlFromString(
      this.apis['room-selector'],
      arrayifyObject(
        removeUndefinedKeys(
          {
            ...this.queryParams,
            ...this.selectedValues,
            ...params,
            ...this.passThroughParams
          }
        )
      )
    )

    const newData = await fetchJsonData(url, { fullReferrerOnCrossOrigin: true })
    this.setEnabledState(true)

    return newData
  }

  /*
  * -----------------------------------------------------
  * SELECTED STATE RELATED METHODS (OVERVIEW / BASKET)
  * -----------------------------------------------------
  * */

  initSelectedState () {
    if (!this.selectedState) {
      const { accommodation, tripSummary, priceBreakdown, optionalServices, roomSelector } = this.localesData
      this.selectedState = new SelectedState(
        this.elements.selectedState,
        {
          locales: {
            accommodation,
            tripSummary,
            priceBreakdown,
            optionalServices,
            roomSelector
          },
          mandatoryCostsCollapsed: this.mandatoryCostsCollapsed
        }
      )
      this.selectedState.events.on('submit', this.handleSelectedStateSubmit, this)
      this.events.emit('bookingGate.selectedStateInit', this)
    }
  }

  handleSelectedStateSubmit (data) {
    domEventsHelper.detachEvents(this._openJawEvents, WIDGET_API)

    if (data.isOpenJaw) {
      this.showOpenJawWarningPopup(data)
    } else {
      this.createBookingDraft(data)
    }
  }

  showOpenJawWarningPopup (data) {
    const openJawModal = this.elements.openJawValidationModal
      ? this.elements.openJawValidationModal[ELEMENT_QUERIES.modalApi]
      : undefined

    if (openJawModal) {
      const modalBody = document.querySelector(ELEMENT_QUERIES.openJawValidationModalBody)
      const airportDictionary = this.flightBusSelector.selectorDataModel.airportDictionary
      const departureAirport = airportDictionary[data.outboundTransportInfo.departureAirportId]
      const arrivalAirport = airportDictionary[data.inboundTransportInfo.arrivalAirportId]
      const bodyText = modalBody.textContent.replace('{DEPARTURE_AIRPORT}', departureAirport).replace('{ARRIVAL_AIRPORT}', arrivalAirport)
      modalBody.textContent = bodyText
      openJawModal.open()
      this.events.emit(bookingGateEvents.BOOKING_GATE_OPEN_JAW_MODAL_OPEN,
        this._mapOpenJawDataForTracking(departureAirport, arrivalAirport))

      this._openJawEvents = [
        [this.elements.openJawValidationModalButton, {
          click: (e) => {
            e.preventDefault()
            e.stopPropagation()
            this.events.emit(bookingGateEvents.BOOKING_GATE_OPEN_JAW_MODAL_SUBMIT,
              this._mapOpenJawDataForTracking(departureAirport, arrivalAirport))
            this.createBookingDraft(data)
          }
        }],
        [this.elements.openJawValidationModalCancelButton, {
          click: (e) => {
            this.events.emit(bookingGateEvents.BOOKING_GATE_OPEN_JAW_MODAL_CLOSE,
              this._mapOpenJawDataForTracking(departureAirport, arrivalAirport))
          }
        }]
      ]
      domEventsHelper.attachEvents(this._openJawEvents, WIDGET_API)
    }
  }

  createBookingDraft (data) {
    this.setEnabledState(false)
    this.selectedState.setLoadingState(true)
    const postData = this.collectDataForBookingDraft(data)
    const returnUrl = buildUrlWithParams(
      document.URL.split('?')[0],
      arrayifyObject({
        ...removeUndefinedKeys({
          ...this.selectedValues,
          Month: undefined,
          DepartureDate: this.selectedPackage.departureDate
        }),
        ...this.whiteListedParams,
        ...this.passThroughParams,
        focusPriceTable: true
      })
    )

    this.events.emit(bookingGateEvents.BOOKING_GATE_BOOKING_SUBMITTED, {
      selectedValues: this._getSelectedValuesForTracking(),
      allocatedPackages: this._getAllocatedPackages(),
      priceClickedData: this.priceClickedData,
      totalOccupation: this._getTotalOccupation(),
      transportData: this.selectedValues.TransportType !== TRANSPORT_TYPE_VALUES.SELF_DRIVE ? this.flightBusSelector.getSelectedTransportData() : undefined,
      totalPrice: data.routeTotalPrice.raw,
      isPromotedPriceOpened: this.elements.promotedPrice && this.elements.promotedPrice.classList.contains('in'),
      originalDepartureDate: this.promotedPrice && this.promotedPrice.getOriginalDepartureDate()
    })

    window.history.replaceState(
      '',
      '',
      returnUrl.href)

    // TODO: Replace with some of the 'json-fetch.js' helper methods?
    window.fetch(this.apis['booking-draft'], {
      credentials: 'include',
      referrerPolicy: 'no-referrer-when-downgrade',
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData)
    })
      .then(response => response.json())
      .then(draftBooking => {
        const extraParams = { ...draftBooking.urlParams, ...{ sid: draftBooking.draftId } }
        const nextLocation = buildUrlWithParams(this.apis['booking-url'], extraParams, { hash: '' })
        document.location.href = nextLocation
      })
      .catch(ex => {
        console.warn('Booking could not be created. ' + ex)

        this.setEnabledState(true)
        this.selectedState.setLoadingState(false)
        this.showStartBookingErrorModal(ex)
      })
  }

  _mapOpenJawDataForTracking (departureAirport, arrivalAirport) {
    return {
      departureAirport,
      arrivalAirport
    }
  }

  /**
   * Collects data to create a booking draft
   * FIXME: 🔥💣💥 Consider serious refactor how the data is obtained, will affect flight-selector and selected-state widgets
   *
   */
  collectDataForBookingDraft (data) {
    const {
      accoid: accommodationId,
      contextitemid
    } = this.queryParams
    const {
      departureDate,
      duration,
      transportType
    } = this.selectedPackage
    const mealplan = this.selectedPackage.packageFilter.mealplan
    const {
      inboundFlightId: inboundFlight,
      outboundFlightId: outboundFlight,
      inboundBusId: inboundBus,
      outboundBusId: outboundBus,
      campaignCode
    } = data
    const participantsGroups = this.allocatedRooms.Participants.map((participantsGroup, i) => ({
      participants: participantsGroup,
      room: this.allocatedRooms.room[i],
      roomContractId: this.allocatedRooms.roomContractId && this.allocatedRooms.roomContractId.length > i && this.allocatedRooms.roomContractId[i]
    }))

    return removeUndefinedKeys({
      accommodationId: parseInt(accommodationId),
      contextitemid,
      departureDate,
      duration,
      transportType,
      mealplan,
      inboundFlight: transportType === TRANSPORT_TYPE_VALUES.FLIGHT ? inboundFlight : undefined,
      outboundFlight: transportType === TRANSPORT_TYPE_VALUES.FLIGHT ? outboundFlight : undefined,
      inboundBus: transportType === TRANSPORT_TYPE_VALUES.BUS ? inboundBus : undefined,
      outboundBus: transportType === TRANSPORT_TYPE_VALUES.BUS ? outboundBus : undefined,
      campaignCode,
      participantsGroups,
      referrerUrl: window.location.href
    })
  }

  _destroyPriceTableByDuration () {
    // Flush booking gate messages
    flush(this.elements.priceTableMessages)
    flush(this.elements.noResults)

    if (this.elements.priceTableContainer) {
      while (this.elements.priceTableContainer.hasChildNodes()) {
        this.elements.priceTableContainer.removeChild(this.elements.priceTableContainer.lastChild)
      }
    }
  }

  /**
 * Creates an observer and subscibes to the enter event
 *
 * @param {*} callBack
 * @param {boolean} [unObserve=false]
 * @memberof BookingGate
 */
  _addObserver (callBack) {
    const observer = documentObserver()
    observer.observe(this.element)
    this.element[observerAPI].events.on('enter', () => {
      callBack(observer)
    })
  }

  _getParticipantsForFlightOnly () {
    const filterParticipants = this.filtersCollection.findWhere('type', FILTER_TYPES.PARTICIPANTS)
    const participants = filterParticipants.values.models.map(model => model.getAttribute('value'))
    return participants
  }

  _getTotalOccupation () {
    const emptyOccupation = {
      total: 0,
      adults: 0,
      children: 0,
      babies: 0
    }

    if (this.roomSelector) {
      return this.roomSelector.getTotalOccupation()
    }

    if (this.flightBusSelector) {
      // when on flight only, we don't have room selector but flight/bus selector
      const participants = this._getParticipantsForFlightOnly()
      let occupation = {
        total: participants.length,
        adults: participants.length,
        children: 0,
        babies: 0
      }

      const profile = this._getParticipantProfile(this.selectedPackage.departureDate)
      if (profile) {
        const ageCategories = participants.map(participant => this._getAgeCategoryForBirthdate(profile, participant))
        const isAnyCategoryWrong = ageCategories.some(category => category === undefined)
        if (!isAnyCategoryWrong) {
          occupation = ageCategories.reduce(
            (occ, ageCategory) => {
              occ[ageCategory] = occ[ageCategory] + 1
              occ.total = occ.total + 1
              return occ
            },
            emptyOccupation)
        }
      }
      return occupation
    }

    return null
  }

  _getAllocatedPackages () {
    if (this.roomSelector) {
      return this.roomSelector.getAllocatedPackages()
    }

    if (this.flightBusSelector) {
      // when on flight only, we don't have room selector but flight/bus selector
      const occupation = this._getTotalOccupation()
      const roomId = FLIGHT_ONLY_DEFAULT_ROOM_CODE
      const packageId = `${roomId}_${occupation.total}:${occupation.total}-${occupation.adults ?? 0}:${occupation.adults ?? 0}-${occupation.children ?? 0}:${occupation.children ?? 0}-${occupation.babies ?? 0}:${occupation.babies ?? 0}`
      const participants = this._getParticipantsForFlightOnly()
      return [{
        roomId,
        packageId,
        occupation,
        roomOccupancyId: roomId,
        roomName: '',
        participants
      }]
    }

    return null
  }

  _getUnallocatedOccupation () {
    if (this.roomSelector) {
      return this.roomSelector.getUnallocatedOccupation()
    }

    if (this.flightBusSelector) {
      // when on flight only, we don't have room selector but flight/bus selector
      return {
        total: 0,
        adults: 0,
        children: 0,
        babies: 0
      }
    }

    return null
  }

  _getParticipantProfile (departureDate) {
    if (this.participantsProfiles && this.participantsProfiles.length) {
      const profile = this.participantsProfiles.find(profile => profile.dateFrom <= departureDate && profile.dateTill >= departureDate)
      if (profile) {
        return profile.participantsProfiles
      }
    }
    return undefined
  }

  _getAgeCategoryForBirthdate (profile, birthdate) {
    const ageCategory = profile && profile[birthdate]
    if (ageCategory &&
      ageCategory !== 'adults' &&
      ageCategory !== 'children' &&
      ageCategory !== 'babies') {
      return undefined
    }
    return ageCategory
  }
}

registerWidget(BookingGate, WIDGET_API)
